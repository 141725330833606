<template>
    <v-dialog
        no-click-animation
        v-model="value"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <the-nav-bar title="Comparativo de planos" @back="close"></the-nav-bar>
            <v-data-table
                :headers="headerOperadoras"
                :items="planos"
                :items-per-page="planos.length"
                group-by="operadora"
                hide-default-footer
                hide-default-header
                mobile-breakpoint="0"
            >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th></th>
                            <th
                                class="header-table justify-center align-center"
                                v-for="(header, index) in headers"
                                :key="index"
                                :colspan="header.cols"
                                :style="`border-bottom: 3px solid ${header.cor}`"
                            >
                                <img
                                v-if="header.operadoraLogo"
                                :src="header.operadoraLogo"
                                alt
                                style="width: 150px; height: auto"
                                :id="`img${index}`"
                                />
                                <div v-else>{{ header.text }}</div>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }" @update:group-by="getColspan(items)">
                    <tbody>
                        <tr v-for="(header, indexH) in headersProdutos" :key="indexH">
                            <td class="text-left">
                                {{ header.text }}
                            </td>
                            <td
                                v-for="(item, index) in items"
                                :key="index"
                                class="text-center"
                            >
                                <span v-if="header.value === 'valorTotal'" :style="formataValor(item[header.value]) === formataValor(menorPreco) ? 'color: green; font-weight: 700; width: 700px;' : 'color: #11010f; font-weight: 700; width: 700px;'">
                                    <v-icon v-if="formataValor(item[header.value]) === formataValor(menorPreco)" color="success" >mdi-checkbox-marked-circle</v-icon>
                                    {{ item[header.value] | currency }}
                                </span>
                                <span v-else-if="header.value === 'credenciados'" style="color: #11010f; font-weight: 700; width: 700px;">
                                    <p style="margin: 0">Laboratórios: {{ item['laboratorios'] }}</p>
                                    <p style="margin: 0">Hospitais: {{ item['hospitais'] }}</p>
                                </span>
                                <span v-else-if="header.value === 'coparticipacao'" style="color: #11010f; font-weight: 700; width: 700px;">
                                    {{ item[header.value] ? "COM COPARTICIPAÇÃO" : "SEM COMPARTICIPAÇÃO" }}
                                </span>
                                <span v-else style="color: #11010f; font-weight: 700; width: 700px;">
                                    {{ item[header.value].toString() }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>

<script>
import TheNavBar from '@/components/TheNavBar.vue';
export default {
    name: 'DialogComparativo',
    components: {
        TheNavBar
    },
    data() {
        return {
            menorPreco: 0,
            loading: false,
            headersProdutos: [
                { value: "nomePlanoAns", text: "Nome do plano" },
                { value: "segmentacao", text: "Tipo" },
                { value: "abrangencia", text: "Cobertura" },
                { value: "acomodacao", text: "Acomodação" },
                { value: "coparticipacao", text: "Fator moderador" },
                { value: "entidades", text: "Entidade" },
                { value: "mesReajuste", text: "Mês reajuste" },
                { value: "credenciados", text: "Credenciados" },
                { value: "valorTotal", text: "Valor total" },
            ],
            headerOperadoras: [],
        };
    },
    props: {
        planos: {
            default: [],
            required: true
        },
        value: {
            required: true
        }
    },
    watch: {
        planos(val) {
            this.getColspan(val)
            this.getMenorPreco(val)
        },
        
    },
    mounted() {
        if(this.planos.length)
            this.getMenorPreco(this.planos)
    },
    methods: {
        formataValor(preco) {
            return parseFloat(preco).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
        },
        close() {
            this.loading = false;
            this.$emit('input', false);
        },
        getColspan(items) {
            const count = {};
            items.forEach(function (x) {
                count[x.operadora] = (count[x.operadora] || 0) + 1;
            });
            const countKeys = Object.keys(count).sort();
            const result = [];
            for (let i = 0; i < countKeys.length; i++) {
                const e = countKeys[i];
                result.push({
                value: e,
                text: e,
                cols: count[e],
                cor: "#461dfc",
                cnpj: items.filter((x) => x.operadora === e)[0].cnpj,
                operadoraLogo: items.filter((x) => x.operadora === e)[0].operadoraLogo,
                });
            }
            this.headerOperadoras = result;
        },
        getMenorPreco(items) {
            let valores = []
            items.forEach(i => valores.push(i.valorTotal))
            this.menorPreco = valores.length ? valores.reduce((min, p) =>{
                return Math.min(min, p)
            }).toString() : 0
        }
    }
}
</script>
<style scoped>
.header-table {
  text-align: center !important;
  color: #000 !important;
  font-size: 1.1em !important;
}
</style>