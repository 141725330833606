var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-5" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { staticClass: "col-12 col-md-6" }, [
                _c("h3", { staticClass: "font-weight-bold" }, [
                  _vm._v(" Preencha os dados de seu cliente: "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-5", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "col-12 col-md-6" },
                [
                  _c(
                    "validation-observer",
                    {
                      ref: "form",
                      staticClass: "mb-10",
                      attrs: { tag: "form", autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c("p", { staticClass: "mb-7" }, [
                                _vm._v(
                                  " Para começar, informe o CEP do titular: "
                                ),
                              ]),
                              _c("async-cep-residencial", {
                                attrs: { cep: _vm.form.cep },
                                on: {
                                  success: function ($event) {
                                    return _vm.successCep($event)
                                  },
                                  error: function ($event) {
                                    return _vm.errorCep()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ loading: loadingCep }) {
                                      return [
                                        _c("base-text-field", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "#####-###",
                                              expression: "'#####-###'",
                                            },
                                          ],
                                          attrs: {
                                            autoBlur: 9,
                                            rules: "required",
                                            inputmode: "numeric",
                                            type: "tel",
                                            id: "cep",
                                            name: "CEP",
                                            label: "CEP",
                                            clearable: "",
                                            outlined: "",
                                            maxlength: "9",
                                            loading: loadingCep,
                                          },
                                          model: {
                                            value: _vm.form.cep,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "cep", $$v)
                                            },
                                            expression: "form.cep",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _vm.showProfissao
                                ? _c("div", [
                                    _c("p", { staticClass: "mb-7" }, [
                                      _vm._v(
                                        " selecione a profissão/entidade: "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                          width: "100%",
                                        },
                                      },
                                      [
                                        _c("async-profissoes", {
                                          key:
                                            "c_profissao-" + _vm.keyProfissao,
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            administradora: [
                                              _vm.dadosAdministradora,
                                            ],
                                            cidade: _vm.endereco.cidade,
                                            estado: _vm.endereco.estado,
                                            entidades: _vm.entidades,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  loading: loadingProfissoes,
                                                  data,
                                                }) {
                                                  return [
                                                    _c(
                                                      "base-autocomplete-field",
                                                      {
                                                        attrs: {
                                                          items: data,
                                                          id: "profissao",
                                                          name: "profissão",
                                                          label: "Profissão",
                                                          mode: "aggressive",
                                                          disabled:
                                                            !_vm.endereco
                                                              .cidade ||
                                                            !_vm.endereco
                                                              .estado,
                                                          "item-text": "nome",
                                                          loading:
                                                            loadingProfissoes,
                                                          outlined: "",
                                                          chips: "",
                                                          "data-test-id":
                                                            "profissão",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectProfissao(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .publicoAlvo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "publicoAlvo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.publicoAlvo",
                                                        },
                                                      }
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2962383132
                                          ),
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              padding: "10px",
                                              "line-height": "2.0",
                                              display: "inline-block",
                                            },
                                          },
                                          [_vm._v("ou")]
                                        ),
                                        _c("async-entidades", {
                                          key: "c_entidade-" + _vm.keyEntidade,
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            fullList: true,
                                            administradora: [
                                              _vm.dadosAdministradora,
                                            ],
                                            cidade: _vm.endereco.cidade,
                                            estado: _vm.endereco.estado,
                                            "publico-alvo":
                                              _vm.form.publicoAlvo,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({
                                                  loading: loading,
                                                  data,
                                                }) {
                                                  return [
                                                    _c(
                                                      "base-autocomplete-field",
                                                      {
                                                        attrs: {
                                                          items: data,
                                                          id: "entidade",
                                                          name: "Entidade",
                                                          label: "Entidade",
                                                          mode: "aggressive",
                                                          disabled:
                                                            !_vm.endereco
                                                              .cidade ||
                                                            !_vm.endereco
                                                              .estado,
                                                          "item-text": "id",
                                                          loading: loading,
                                                          "data-test-id":
                                                            "entidade",
                                                          outlined: "",
                                                          multiple: "",
                                                          chips: "",
                                                          "return-object": "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.keyProfissao++
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "selection",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "v-chip",
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          "input-value":
                                                                            data.selected,
                                                                          close:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          "click:close":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.removerEntidade(
                                                                                data
                                                                                  .item
                                                                                  .id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      "v-chip",
                                                                      data.attrs,
                                                                      false
                                                                    )
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.form.entidades,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "entidades",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.entidades",
                                                        },
                                                      }
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2551005176
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#171a88",
                                              cursor: "pointer",
                                              "text-align": "left",
                                            },
                                            on: { click: _vm.limparFiltros },
                                          },
                                          [_vm._v("Limpar filtros")]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("v-divider", {
                                staticStyle: { "margin-bottom": "12px" },
                              }),
                              _vm.form.publicoAlvo ||
                              (_vm.form.entidades && _vm.form.entidades.length)
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "mb-7" }, [
                                        _vm._v(
                                          " Qual a faixa etária dos beneficiários? "
                                        ),
                                      ]),
                                      _c(
                                        "v-row",
                                        { staticClass: "grid-container" },
                                        _vm._l(
                                          _vm.dataNascimento,
                                          function (item) {
                                            return _c(
                                              "v-col",
                                              { key: item.id },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.texto)),
                                                ]),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    id: "dataNascimento",
                                                    "append-icon": "mdi-minus",
                                                    "append-outer-icon":
                                                      "mdi-plus",
                                                  },
                                                  on: {
                                                    "click:append": function (
                                                      $event
                                                    ) {
                                                      return _vm.decreaseData(
                                                        item
                                                      )
                                                    },
                                                    "click:append-outer":
                                                      function ($event) {
                                                        return _vm.increaseData(
                                                          item
                                                        )
                                                      },
                                                  },
                                                  model: {
                                                    value: item.count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.count",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showBotaoContinuar
                        ? _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "text-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text",
                                      attrs: {
                                        type: "submit",
                                        elevation: "10",
                                        color: "primary",
                                        loading: _vm.loading,
                                        "data-test-id": "salvar-e-continuar-1",
                                      },
                                    },
                                    [_vm._v(" CONTINUAR ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }