<template>
  <div>
    <v-navigation-drawer v-model="drawerComp" app right temporary disable-resize-watcher v-click-outside="handleClickOutside" class="sidebar">
      <div class="sidebar-content">
        <v-row>
          <v-col cols="6">
            <div class="sidebar-title">
              Filtros
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn text class="btn-limpar text-none pa-0" @click.prevent="limparFiltro">
              Limpar
            </v-btn>
          </v-col>
        </v-row>

        <validation-observer ref="form" @submit.prevent="submit" tag="form">
          <v-row class="mb-1 mx-5">
            <v-col class="pa-0">
              <base-wrapper-dialog-options
                :key="'a_operadoras-0'"
                headerTitle="Operadora"
                :options="filtros.dataOperadora ? filtros.dataOperadora : []"
                @select="$set(form, 'operadora', $event.id)"
                value-key="id"
                label-key="nome"
                ref="dialogOperadora"
                @open="modalOperadora = true"
                @close="modalOperadora = false"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    :loading="!filtros.dataOperadora"
                    readonly
                    id="operadora"
                    placeholder="Clique aqui para selecionar"
                    name="Operadora"
                    v-on="on"
                    :value="form.operadora"
                    label="Operadora"
                    outlined
                    append-icon="mdi-chevron-right"
                    hide-details
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
            </v-col>
          </v-row>

          <v-row class="mb-0 mx-5">
            <v-col class="pa-0">
              <base-wrapper-dialog-options
                :key="'a_abrangencia-0'"
                headerTitle="Abrangencia"
                :options="filtros.dataAbrangencia ? filtros.dataAbrangencia : []"
                @select="$set(form, 'abrangencia', $event.id)"
                value-key="id"
                label-key="nome"
                ref="dialogAbrangencia"
                @open="modalAbrangencia = true"
                @close="modalAbrangencia = false"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    :loading="!filtros.dataAbrangencia"
                    readonly
                    id="abrangencia"
                    placeholder="Clique aqui para selecionar"
                    name="Abrangencia"
                    v-on="on"
                    :value="form.abrangencia"
                    label="Abrangencia"
                    outlined
                    append-icon="mdi-chevron-right"
                    hide-details
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
            </v-col>
          </v-row>

          <v-row class="mb-0 mx-5">
            <v-col class="pa-0">
              <base-wrapper-dialog-options
                :key="'a_acomodacao-0'"
                headerTitle="Acomodação"
                :options="filtros.dataAcomodacao ? filtros.dataAcomodacao : []"
                @select="$set(form, 'acomodacao', $event.id)"
                value-key="id"
                label-key="nome"
                ref="dialogAcomodacao"
                @open="modalAcomodacao = true"
                @close="modalAcomodacao = false"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    :loading="!filtros.dataAcomodacao"
                    readonly
                    id="acomodacao"
                    placeholder="Clique aqui para selecionar"
                    name="Acomodação"
                    v-on="on"
                    :value="form.acomodacao"
                    label="Acomodação"
                    outlined
                    append-icon="mdi-chevron-right"
                    hide-details
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
            </v-col>
          </v-row>

          <v-row class="mb-0 mx-5">
            <v-col class="pa-0">
              <base-wrapper-dialog-options
                :key="'c_coparticipacao-0'"
                headerTitle="Coparticipação"
                :options="filtro.dataCoparticipacao"
                @select="$set(form, 'coparticipacao', $event.nome)"
                value-key="id"
                label-key="nome"
                ref="dialogCoparticipaca"
                @open="modalCoparticipacao = true"
                @close="modalCoparticipacao = false"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    :loading="false"
                    readonly
                    id="coparticipacao"
                    placeholder="Clique aqui para selecionar"
                    name="Coparticipação"
                    v-on="on"
                    :value="form.coparticipacao"
                    label="Coparticipação"
                    outlined
                    append-icon="mdi-chevron-right"
                    hide-details
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
            </v-col>
          </v-row>
          <v-row class="mx-5">
            <v-col class="pa-0">
              <h2 class="primary--text subtitle-1 font-weight-bold">
                Intervalo de Preço
              </h2>
            </v-col>
          </v-row>
          <v-row class="mx-5">
            <v-col sm="12" md="2" class="pa-0">
              <v-text-field v-model="price[0]" class="mt-0 pt-0" hide-details single-line type="number"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col sm="12" md="2" class="pa-0">
              <v-text-field v-model="price[1]" class="mt-0 pt-0" hide-details single-line type="number"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-5">
            <v-col cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0 px-0 py-2">
              <v-range-slider class="rangePrice" v-model="price" :max="24000" :min="0" :step="1" hide-details></v-range-slider>
            </v-col>
          </v-row>
          <v-row class="mb-0 mx-5">
            <v-col class="pa-0">
              <base-wrapper-dialog-options
                :key="'c_ordenar-0'"
                headerTitle="Ordenar"
                :options="filtro.dataOrdenar"
                @select="$set(form, 'ordenar', $event.nome)"
                value-key="nome"
                label-key="nome"
                ref="dialogOrdenar"
                @open="modalOrdenacao = true"
                @close="modalOrdenacao = false"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    :loading="false"
                    readonly
                    id="ordenar"
                    placeholder="Por Preço"
                    name="Ordenar"
                    v-on="on"
                    :value="form.ordenar"
                    label="Ordenar"
                    outlined
                    append-icon="mdi-chevron-right"
                    hide-details
                  ></base-text-field>
                </template>
              </base-wrapper-dialog-options>
            </v-col>
          </v-row>

          <v-row class="mb-2 mx-5">
            <v-col class="pa-0">
              <base-wrapper-dialog-list
                :key="'d_rede-referenciada-0'"
                headerTitle="Rede Médica"
                :options="listaPrestadores"
                label-key="prestador"
                refKey="idProdutoFatura"
                value-key="id"
                :loading="loadingRede"
                :selectMultiplos="true"
                return-object
                @select="selectHospital"
                @open="modalRedeMedica = true"
                @close="modalRedeMedica = false"
              >
                <template v-slot:activador="{ on }">
                  <base-text-field
                    inputmode="text"
                    v-on="on"
                    readonly
                    id="redeReferenciada"
                    name="Rede Médica"
                    placeholder="Selecione"
                    label="Rede Médica"
                    outlined
                    :value="hospitaisSelecionados"
                    append-icon="mdi-chevron-right"
                    :loading="loadingRede"
                    hide-details
                  ></base-text-field>
                </template>
                <template v-slot:options="">
                  <base-wrapper-dialog-options
                    class="mt-10"
                    :key="'c_cidades-0'"
                    headerTitle="Cidades"
                    :options="redeReferenciada.cidades"
                    @select="selectCidadePrestadores"
                    value-key="nome"
                    label-key="nome"
                    ref="dialogCidades"
                  >
                    <template v-slot:activador="{ on }">
                      <base-text-field
                        :loading="false"
                        readonly
                        id="cidade"
                        placeholder="Clique aqui para selecionar"
                        name="Cidades"
                        v-on="on"
                        :value="cidadeSelecionada"
                        label="Cidades"
                        outlined
                        append-icon="mdi-chevron-right"
                      ></base-text-field>
                    </template>
                  </base-wrapper-dialog-options>
                </template>
              </base-wrapper-dialog-list>
            </v-col>
          </v-row>
        </validation-observer>
        <v-divider class="mx-5 mt-3" />
        <div class="px-5 mt-3">
          <v-btn block x-large color="primary" @click.prevent="enviarFiltro"> FILTRAR </v-btn>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import redeService from "@/services/redeReferenciadaService";
import ClickOutside from "vue-click-outside";
import { mapState } from "vuex";
import BaseWrapperDialogOptions from "@/components/BaseWrapperDialogOptions.vue";
import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
import BaseTextField from "@/components/BaseTextField.vue";

export default {
  components: {
    BaseTextField,
    BaseWrapperDialogOptions,
    BaseWrapperDialogList,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      price: [0, 24000],
      loading: false,
      loadingRede: true,
      cidadeSelecionada: "",
      listaPrestadores: [],
      hospitalid: [],
      hospitaisSelecionados: [],
      redeReferenciada: {
        prestadores: [],
        cidades: [],
      },
      form: {},
      filtros: {},
      filtro: {
        dataOrdenar: [
          {
            id: "Preco",
            nome: "Por Preço",
          },
          {
            id: "Nome",
            nome: "Por nome",
          },
        ],
        dataAbrangencia: [],
        dataAcomodacao: [],
        dataCoparticipacao: [
          {
            id: true,
            nome: "Sim",
          },
          {
            id: false,
            nome: "Não",
          },
        ],
      },
      modalOperadora: false,
      modalAbrangencia: false,
      modalAcomodacao: false,
      modalCoparticipacao: false,
      modalOrdenacao: false,
      modalRedeMedica: false,
    };
  },
  props: {
    drawer: {
      default: false,
      type: Boolean,
    },
    planos: {
      type: [Array, Object],
      default: () => {},
    },
    UF: String,
    value: {
      default: false,
    },
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.cadastroProposta.proposta,
    }),
    drawerComp: {
      get() {
        return this.drawer;
      },
      set() {},
    },
  },
  watch: {
    planos: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.getRedeMedica();
          this.verFiltro(this.planos);
        }
      },
    },
  },
  methods: {
    limpar() {
      this.$emit("clear-filters");
    },
    handleClickOutside() {
      if (!this.modalOperadora && !this.modalAbrangencia && !this.modalAcomodacao && !this.modalCoparticipacao && !this.modalOrdenacao && !this.modalRedeMedica) this.$emit("close");
    },
    selectHospital(value) {
      this.hospitalid = value.data;
      this.hospitaisSelecionados = [];
      Object.keys(value.nome).forEach((e) => {
        if (value.nome[e]) this.hospitaisSelecionados.push(e);
      });
    },
    selectCidadePrestadores(cidade) {
      this.cidadeSelecionada = cidade.nome;
      this.listaPrestadores = this.redeReferenciada.prestadores.filter((Element) => Element.cidade === this.cidadeSelecionada);
    },
    verFiltro(planos) {
      if (Object.keys(this.filtros).length === 0) {
        const filtroAbrangencia = [];
        const filtroOperadora = [];
        const uniqueOperadora = [...new Set(planos.map((item) => item.operadora))];
        uniqueOperadora.forEach((operadora) => {
          filtroOperadora.push({
            nome: operadora,
            id: operadora,
          });
        });

        const uniqueAbrangencia = [...new Set(planos.map((item) => item.abrangencia))];

        uniqueAbrangencia.forEach((abrangencia) => {
          filtroAbrangencia.push({
            nome: abrangencia,
            id: abrangencia,
          });
        });

        this.filtros.dataOperadora = filtroOperadora;
        this.filtros.dataAbrangencia = filtroAbrangencia;

        const filtroAcomodacao = [];

        const uniqueAcomodacao = [...new Set(planos.map((item) => item.acomodacao))];

        uniqueAcomodacao.forEach((acomodacao) => {
          filtroAcomodacao.push({
            nome: acomodacao,
            id: acomodacao,
          });
        });
        this.filtros.dataAcomodacao = filtroAcomodacao;
      }

      this.dialogFiltro = true;
    },
    enviarFiltro() {
      this.filtrarPlanos({
        campos: this.form,
        hospitais: this.hospitalid,
        rangePreco: this.price,
      });
    },
    /* eslint-disable */
    filtrarPlanos(value) {
      const filter = [];
      let planosfiltered = this.planos;
      if (value.campos.abrangencia) filter.push("value.campos.abrangencia == obj.abrangencia");
      if (value.campos.operadora) filter.push("value.campos.operadora == obj.operadora");
      if (value.campos.acomodacao) filter.push("value.campos.acomodacao == obj.acomodacao");
      if (value.campos.coparticipacao) {
        if (value.campos.coparticipacao === "Sim") filter.push("obj.coparticipacao == true");
        else filter.push("obj.coparticipacao == false");
      }

      if (value.hospitais && Object.values(value.hospitais).length) {
        const array = Object.values(value.hospitais);
        let arrIdProdutos = [];

        array.forEach((el) => {
          arrIdProdutos = [...arrIdProdutos, ...el];
        });
        planosfiltered = planosfiltered.filter((plano) => arrIdProdutos.includes(plano.idProdutoFatura.toString()));
      }

      if (filter.length) {
        planosfiltered = this.planos.filter((obj) => eval(filter.join(" && ")));
      }

      if (value.campos.ordenar && value.campos.ordenar === "Por nome") {
        planosfiltered.sort((a, b) => a.nomePlano.localeCompare(b.nomePlano));
      }

      if (value.campos.ordenar && value.campos.ordenar === "Por Preço") {
        planosfiltered.sort((a, b) => parseFloat(a.valorTotal) - parseFloat(b.valorTotal));
      }
      if (value.rangePreco && value.rangePreco.length) {
        const objFiltros = {
          valorMinimo: value.rangePreco[0],
          valorMaximo: value.rangePreco[1],
        };
        planosfiltered = planosfiltered.filter((plano) => plano.valorTotal < objFiltros.valorMaximo && plano.valorTotal > objFiltros.valorMinimo);
      }

      this.$emit("resultado", planosfiltered);
    },
    /* eslint-enable */
    limparFiltro() {
      this.form = {};
      this.price = [0, 24000];
      this.hospitaisSelecionados = [];
      this.hospitalid = [];
      this.listaPrestadores = [];
      this.cidadeSelecionada = "";
      this.$emit("limparFiltro");
    },
    close() {
      this.$emit("input", false);
    },
    async getRedeMedica() {
      try {
        this.loadingRede = true;
        const idProdutoFatura = this.planos.map((e) => e.idProdutoFatura);
        const data = await redeService.getRedeReferenciada({
          idProdutoFatura,
          UF: this.UF,
        });
        const cidades = [];
        this.redeReferenciada = data.data;
        this.redeReferenciada.cidades.forEach((e) => {
          cidades.push({ nome: e });
        });
        // eslint-disable-next-line no-nested-ternary
        cidades.sort((a, b) => (a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0));
        this.redeReferenciada.cidades = cidades;
        this.$emit("success", data);
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: error.message });
        this.$emit("error");
      } finally {
        this.loadingRede = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.sidebar {
  height: 100%;
  width: 100%;

  @media (max-width: $media-sm) {
    min-width: 80%;
  }
  @media (min-width: $media-sm) and (max-width: $media-md) {
    min-width: 60%;
  }
  @media (min-width: $media-md) and (max-width: $media-lg) {
    min-width: 40%;
  }
  @media (min-width: $media-lg) and (max-width: $media-xl) {
    min-width: 30%;
  }
  @media (min-width: $media-xl) {
    min-width: 18%;
  }
}

.sidebar-content {
  transform: scale(0.9);
  transform-origin: center center;
  margin: -2.8rem -1.6rem 0 -1.6rem;
}

.sidebar-title {
  color: $azul-qualicorp-hex;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.3rem 1rem 1rem 1rem;
}

.btn-limpar {
  color: $azul-qualicorp-hex;
  font-size: 1.3rem;
  margin: 1rem;
  cursor: pointer;
  text-decoration: underline 2px;
  text-underline-offset: 4px;
}
</style>
