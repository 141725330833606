var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: "",
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("the-nav-bar", {
            attrs: { title: "Comparativo de planos" },
            on: { back: _vm.close },
          }),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headerOperadoras,
              items: _vm.planos,
              "items-per-page": _vm.planos.length,
              "group-by": "operadora",
              "hide-default-footer": "",
              "hide-default-header": "",
              "mobile-breakpoint": "0",
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function ({ props: { headers } }) {
                  return [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th"),
                          _vm._l(headers, function (header, index) {
                            return _c(
                              "th",
                              {
                                key: index,
                                staticClass:
                                  "header-table justify-center align-center",
                                style: `border-bottom: 3px solid ${header.cor}`,
                                attrs: { colspan: header.cols },
                              },
                              [
                                header.operadoraLogo
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "auto",
                                      },
                                      attrs: {
                                        src: header.operadoraLogo,
                                        alt: "",
                                        id: `img${index}`,
                                      },
                                    })
                                  : _c("div", [_vm._v(_vm._s(header.text))]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "body",
                fn: function ({ items }) {
                  return [
                    _c(
                      "tbody",
                      _vm._l(_vm.headersProdutos, function (header, indexH) {
                        return _c(
                          "tr",
                          { key: indexH },
                          [
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(" " + _vm._s(header.text) + " "),
                            ]),
                            _vm._l(items, function (item, index) {
                              return _c(
                                "td",
                                { key: index, staticClass: "text-center" },
                                [
                                  header.value === "valorTotal"
                                    ? _c(
                                        "span",
                                        {
                                          style:
                                            _vm.formataValor(
                                              item[header.value]
                                            ) ===
                                            _vm.formataValor(_vm.menorPreco)
                                              ? "color: green; font-weight: 700; width: 700px;"
                                              : "color: #11010f; font-weight: 700; width: 700px;",
                                        },
                                        [
                                          _vm.formataValor(
                                            item[header.value]
                                          ) === _vm.formataValor(_vm.menorPreco)
                                            ? _c(
                                                "v-icon",
                                                { attrs: { color: "success" } },
                                                [
                                                  _vm._v(
                                                    "mdi-checkbox-marked-circle"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  item[header.value]
                                                )
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      )
                                    : header.value === "credenciados"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#11010f",
                                            "font-weight": "700",
                                            width: "700px",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { margin: "0" } },
                                            [
                                              _vm._v(
                                                "Laboratórios: " +
                                                  _vm._s(item["laboratorios"])
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticStyle: { margin: "0" } },
                                            [
                                              _vm._v(
                                                "Hospitais: " +
                                                  _vm._s(item["hospitais"])
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : header.value === "coparticipacao"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#11010f",
                                            "font-weight": "700",
                                            width: "700px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item[header.value]
                                                  ? "COM COPARTICIPAÇÃO"
                                                  : "SEM COMPARTICIPAÇÃO"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#11010f",
                                            "font-weight": "700",
                                            width: "700px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item[header.value].toString()
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }