var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            dark: "",
            dense: "",
            color: _vm.$store.getters.getColor("primary"),
            fixed: "",
          },
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-test-id": "voltar" },
              on: { click: _vm.back },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "espacamento-linhas pt-1" }, [
            _vm.editando
              ? _c("div", { staticClass: "page-title" }, [
                  _vm._v("Editar Simulação"),
                ])
              : _c("div", { staticClass: "page-title" }, [
                  _vm._v("Criar Simulação"),
                ]),
            _c("div", { staticClass: "page-step" }, [
              _vm._v(_vm._s(_vm.pages[_vm.step].nome)),
            ]),
          ]),
          _c("v-spacer"),
          _vm.step === 2
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    small: "",
                    icon: "",
                    dark: "",
                    "data-test-id": "buscar",
                  },
                  on: { click: _vm.buscar },
                },
                [_c("v-icon", [_vm._v("mdi-magnify")])],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: { icon: "", "data-test-id": "atualizar" },
              on: { click: _vm.atualizar },
            },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          ),
        ],
        1
      ),
      _c(_vm.pages[_vm.step].component, {
        ref: "component",
        tag: "component",
        on: { next: _vm.next, back: _vm.back },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }