var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "text-caption text-center" }, [
                  _vm._v(
                    "Os valores podem ser reajustados na vigência selecionada durante a contratação"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "v-row",
                { staticClass: "pb-15" },
                [
                  _c("v-col", { staticClass: "col-12" }, [
                    _vm.filtroAtivo
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-center ma-2 subtitle-2 grey--text",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.planos.length) +
                                " registro(s) filtrados de " +
                                _vm._s(_vm.todosPlanos.length) +
                                " encontrados(s)"
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "text-center ma-2 subtitle-2 grey--text",
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.planos.length) +
                                " registro(s) encontrados(s)"
                            ),
                          ]
                        ),
                  ]),
                  _c("base-card-details-plan", {
                    on: {
                      "select-plano": _vm.handleSelectPlano,
                      selecionar: function ($event) {
                        return _vm.selecionaPlano(_vm.plano)
                      },
                      detalhes: _vm.verDetalhes,
                    },
                    model: {
                      value: _vm.planos,
                      callback: function ($$v) {
                        _vm.planos = $$v
                      },
                      expression: "planos",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _vm.showButton
        ? _c(
            "v-btn",
            {
              staticClass: "mt-10",
              staticStyle: { bottom: "30px", right: "130px" },
              attrs: {
                color: "primary",
                fixed: "",
                dark: "",
                right: "",
                bottom: "",
                fab: "",
                "data-test-id": "continuar",
              },
              on: {
                click: function ($event) {
                  _vm.dialogComparativo = true
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "tooltip-button" },
                [
                  _c("v-icon", [_vm._v("mdi-compare")]),
                  _c("span", { staticClass: "tooltip-button-text" }, [
                    _vm._v("Comparar produtos"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showButton
        ? _c(
            "v-btn",
            {
              staticClass: "mt-10",
              staticStyle: { bottom: "30px", right: "60px" },
              attrs: {
                color: "primary",
                fixed: "",
                dark: "",
                right: "",
                bottom: "",
                fab: "",
                "data-test-id": "continuar",
              },
              on: {
                click: function ($event) {
                  return _vm.continuar()
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "tooltip-button" },
                [
                  _c("v-icon", [_vm._v("mdi-send")]),
                  _c("span", { staticClass: "tooltip-button-text" }, [
                    _vm._v("Continuar Simulação"),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("dialog-comparativo", {
        attrs: { planos: _vm.planosSelecionados },
        on: {
          close: function ($event) {
            _vm.dialogComparativo = false
          },
        },
        model: {
          value: _vm.dialogComparativo,
          callback: function ($$v) {
            _vm.dialogComparativo = $$v
          },
          expression: "dialogComparativo",
        },
      }),
      _c("dialog-plano", {
        attrs: {
          plano: _vm.planoDetalhes,
          titular: this.dadosTitular,
          endereco: this.dadosEndereco,
          tipo: "simulacao",
        },
        on: {
          selecionarplano: _vm.selecionarPlano,
          close: function ($event) {
            _vm.dialogPlano = false
          },
        },
        model: {
          value: _vm.dialogPlano,
          callback: function ($$v) {
            _vm.dialogPlano = $$v
          },
          expression: "dialogPlano",
        },
      }),
      _vm.planos
        ? _c("dialog-filtro", {
            ref: "filtro",
            attrs: {
              drawer: _vm.dialogFiltro,
              planos: _vm.todosPlanos,
              UF: this.dadosEndereco.estado,
            },
            on: {
              limparFiltro: _vm.limparFiltro,
              resultado: _vm.planosFiltrados,
              close: function ($event) {
                _vm.dialogFiltro = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }