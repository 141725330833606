var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "h2",
            { staticClass: "primary--text mb-2 subtitle-1 font-weight-bold" },
            [_vm._v("Produtos Opcionais")]
          ),
          _c("v-divider"),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c("v-progress-circular", {
                staticClass: "mt-4",
                attrs: { indeterminate: "", color: "primary" },
              }),
            ],
            1
          )
        : _vm.produtosOpcionais.length
        ? _c(
            "v-expansion-panels",
            {
              staticClass: "mt-4",
              attrs: { focusable: "", inset: "", multiple: "" },
              model: {
                value: _vm.panels,
                callback: function ($$v) {
                  _vm.panels = $$v
                },
                expression: "panels",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                _vm._l(
                  _vm.produtosOpcionais,
                  function (produtoOpcional, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        attrs: {
                          xs: "12",
                          sm: "12",
                          md: "12",
                          lg: "6",
                          xl: "6",
                        },
                      },
                      [
                        _c(
                          "v-expansion-panel",
                          { staticClass: "mb-3 ma-2" },
                          [
                            _c(
                              "v-expansion-panel-header",
                              { attrs: { tile: "" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mr-4" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "25" } },
                                          [_vm._v("mdi-file-document-multiple")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            produtoOpcional.nomePlanoAns ||
                                              "Produto Opcional"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              [
                                _c("div", { staticClass: "mb-2 mt-2" }, [
                                  _c("b", [_vm._v("Nome")]),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        produtoOpcional.nomePlanoAns || "--"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c("b", [_vm._v("Valor Total")]),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          produtoOpcional.valorTotal
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("v-divider", { staticClass: "mb-2" }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-2 d-flex justify-space-between",
                                  },
                                  [
                                    _c("div", [
                                      _c("b", [_vm._v("Operadora")]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            produtoOpcional.operadora || "--"
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("b", [_vm._v("Abrangência")]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            produtoOpcional.abrangencia || "--"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-2 d-flex justify-space-between",
                                  },
                                  [
                                    _c("div", [
                                      _c("b", [_vm._v("Segmentação")]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            produtoOpcional.segmentacao || "--"
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("b", [_vm._v("Reembolso")]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            produtoOpcional.reembolso
                                              ? "Sim"
                                              : "Não"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "mb-2" }, [
                                  _c("b", [_vm._v("Observação")]),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(produtoOpcional.observacao || "--")
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "primary--text mb-2 subtitle-1 font-weight-bold",
                                  },
                                  [_vm._v("Beneficiários")]
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  _vm._l(
                                    produtoOpcional.beneficiarios,
                                    function (beneficiario, index) {
                                      return _c(
                                        "v-col",
                                        { key: index, attrs: { cols: "12" } },
                                        [
                                          _c("base-card-beneficiary", {
                                            attrs: {
                                              nome:
                                                beneficiario.chave ||
                                                _vm.formataData(
                                                  beneficiario.dataNascimento
                                                ),
                                              valor:
                                                beneficiario.valorContratual,
                                              valorSemDesconto:
                                                beneficiario.valorContratualSemDesconto,
                                              tipo: _vm.isTipo(beneficiario),
                                              avatar: _vm.isTipo(beneficiario),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "d-flex justify-center" }, [
            _c("span", { staticClass: "mt-4" }, [
              _vm._v(" Nenhum registro encontrado "),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }