<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <div class="text-caption text-center">Os valores podem ser reajustados na vigência selecionada durante a contratação</div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
      </div>
      <v-row class="pb-15" v-else>
        <v-col class="col-12">
          <div v-if="filtroAtivo" class="text-center ma-2 subtitle-2 grey--text">{{ planos.length }} registro(s) filtrados de {{ todosPlanos.length }} encontrados(s)</div>
          <div v-else class="text-center ma-2 subtitle-2 grey--text">{{ planos.length }} registro(s) encontrados(s)</div>
        </v-col>
        <base-card-details-plan v-model="planos" @select-plano="handleSelectPlano" @selecionar="selecionaPlano(plano)" @detalhes="verDetalhes" />
      </v-row>
    </v-container>
    <v-btn v-if="showButton" color="primary" fixed dark right bottom fab class="mt-10" style="bottom: 30px; right: 130px" @click="dialogComparativo = true" data-test-id="continuar">
      <span class="tooltip-button">
        <v-icon>mdi-compare</v-icon>
        <span class="tooltip-button-text">Comparar produtos</span>
      </span>
    </v-btn>
    <v-btn v-if="showButton" color="primary" fixed dark right bottom fab class="mt-10" style="bottom: 30px; right: 60px" @click="continuar()" data-test-id="continuar">
      <span class="tooltip-button">
        <v-icon>mdi-send</v-icon>
        <span class="tooltip-button-text">Continuar Simulação</span>
      </span>
    </v-btn>
    <dialog-comparativo
      :planos="planosSelecionados"
      v-model="dialogComparativo"
      @close="dialogComparativo = false"
    ></dialog-comparativo>
    <dialog-plano
      :plano="planoDetalhes"
      :titular="this.dadosTitular"
      :endereco="this.dadosEndereco"
      tipo="simulacao"
      @selecionarplano="selecionarPlano"
      v-model="dialogPlano"
      @close="dialogPlano = false"
    ></dialog-plano>
    <dialog-filtro
      ref="filtro"
      v-if="planos"
      :drawer="dialogFiltro"
      :planos="todosPlanos"
      :UF="this.dadosEndereco.estado"
      @limparFiltro="limparFiltro"
      @resultado="planosFiltrados"
      @close="dialogFiltro = false"
    ></dialog-filtro>
  </div>
</template>

<script>
import planoService from "@/services/planoService";
import BaseCardDetailsPlan from "@/components/simulacoes/BaseNewCardDetailsPlan.vue";
import { mapActions, mapState } from "vuex";
import DialogPlano from "@/components/planos/Detalhes.vue";
import DialogComparativo from "@/components/planos/DialogComparativo.vue";
import DialogFiltro from "@/components/planos/FiltroLateral.vue";

export default {
  name: "Planos",
  data() {
    return {
      planosSelecionados: {},
      entidadesAgrupadas: [],
      planos: [],
      todosPlanos: [],
      loading: false,
      loadingSelecionar: false,
      planoSelected: null,
      planoDetalhes: null,
      selectedPlano: null,
      dialogPlano: false,
      dialogComparativo: false,
      filtros: {},
      dialogFiltro: false,
      filtroAtivo: false
    };
  },
  components: { BaseCardDetailsPlan, DialogPlano, DialogFiltro, DialogComparativo },
  created() {
    this.getLista();
  },
  computed: {
    ...mapState({
      dadosAdministradora: (state) => state.simulacao.administradora,
      dadosTitular: (state) => state.simulacao.titular,
      dadosEndereco: (state) => state.simulacao.endereco,
      dadosDependentes: (state) => state.simulacao.dependentes,
      corretora: (state) => state.simulacao.corretora
    }),
    showButton() {
      return this.entidadesAgrupadas?.length >= 1;
    },
  },
  methods: {
    handleSelectPlano({ entidadesAgrupadas, planosAgrupados }) {
      this.entidadesAgrupadas = entidadesAgrupadas;
      this.planosSelecionados = planosAgrupados;
    },
    ...mapActions({
      setPlano: "simulacao/setPlano",
      setTitular: "simulacao/setTitular",
    }),
    abrirBusca() {
      this.dialogFiltro = true;
    },
    async getLista() {
      const entidade = this.dadosTitular.entidades.map((e) => e.id);
      const beneficiarios = [];
      beneficiarios.push({ chave: "", dataNascimento: this.dadosTitular.dataNascimento });
      this.dadosDependentes.forEach((dep) => {
        beneficiarios.push({ chave: "", dataNascimento: dep.dataNascimento });
      });
      const input = {
        beneficiarios,
        cidade: this.dadosEndereco.cidade,
        entidade,
        publicoAlvo: this.dadosTitular.publicoAlvo,
        uf: this.dadosEndereco.estado,
        cpfCnpjCorretora: this.corretora
      };
      try {
        this.loading = true;
        const data = await planoService.getPlanoSimulacao({
          input,
          administradora: this.dadosAdministradora,
        });
        const planosAgrupados = this.agruparPlanos(data.data);
        this.todosPlanos = planosAgrupados;
        this.planos = planosAgrupados;
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    agruparPlanos(data) {
      const planosAgrupados = [];
      const idProdutoFaturas = data.map((e) => e.idProdutoFatura);
      const disIdProdutoFatura = idProdutoFaturas.filter((value, index, self) => self.indexOf(value) === index);
      disIdProdutoFatura.forEach((idProdutoFatura) => {
        const planos = data.filter((plano) => plano.idProdutoFatura === idProdutoFatura);
        const entidades = planos.map((plano) => plano.entidade).filter((value, index, self) => self.indexOf(value) === index);
        planosAgrupados.push({
          ...planos[0],
          entidades: entidades || [],
        });
      });
      return planosAgrupados.sort((a, b) => parseFloat(b.valorTotal) - parseFloat(a.valorTotal));
    },
    planosFiltrados(data) {
      this.filtroAtivo = true;
      this.planos = data;
      this.dialogFiltro = false;
    },
    selecionarPlano(plano) {
      this.selectedPlano = plano;
    },
    verDetalhes(plano) {
      this.planoDetalhes = plano;
      this.dialogPlano = true;
    },
    limparFiltro() {
      this.filtroAtivo = false;
      this.planos = this.todosPlanos;
      this.dialogFiltro = false;
    },
    continuar() {
      const titular = {
        ...this.dadosTitular,
      };
      this.setPlano({
        plano: this.planosSelecionados,
      });
      this.setTitular({ titular });
      this.$emit("next");
    },
  },
};
</script>

<style></style>
