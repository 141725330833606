var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-5" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { staticClass: "col-12 col-md-6" }, [
                _c("h3", { staticClass: "font-weight-bold" }, [
                  _vm._v(" Para salvar sua simulação, preencha os dados: "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "validation-observer",
            {
              ref: "form",
              staticClass: "mb-10",
              attrs: { tag: "form", autocomplete: "off" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "mt-5", attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("base-text-field", {
                        attrs: {
                          mode: "aggressive",
                          rules: "required|special_characters",
                          id: "nome",
                          name: "Nome completo",
                          label: "Nome completo *",
                          clearable: "",
                          outlined: "",
                          "data-test-id": "nome-completo",
                        },
                        model: {
                          value: _vm.form.nome,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nome", $$v)
                          },
                          expression: "form.nome",
                        },
                      }),
                      _c("base-text-field", {
                        attrs: {
                          type: "email",
                          rules: "required|email",
                          id: "email",
                          name: "E-mail",
                          label: "E-mail *",
                          clearable: "",
                          outlined: "",
                          "data-test-id": "e-mail",
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("base-wrapper-dialog-options", {
                                ref: "dialogtipoTelefone",
                                attrs: {
                                  headerTitle: "Tipo telefone",
                                  options: [
                                    { id: "CELULAR", nome: "CELULAR" },
                                    { id: "RESIDENCIAL", nome: "RESIDENCIAL" },
                                    { id: "COMERCIAL", nome: "COMERCIAL" },
                                  ],
                                  "value-key": "id",
                                  "label-key": "nome",
                                },
                                on: {
                                  select: function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "tipoTelefone",
                                      $event.id
                                    )
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activador",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "base-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                readonly: "",
                                                id: "tipoTelefone",
                                                name: "Tipo telefone",
                                                rules: "required",
                                                value: _vm.form.tipoTelefone,
                                                placeholder:
                                                  "Clique aqui para selecionar",
                                                label: "Tipo telefone *",
                                                outlined: "",
                                                "append-icon":
                                                  "mdi-chevron-right",
                                                "data-test-id": "tipo-telefone",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("base-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["## #####-####", "## ####-####"],
                                    expression:
                                      "['## #####-####', '## ####-####']",
                                  },
                                ],
                                attrs: {
                                  type: "tel",
                                  rules:
                                    "required|ddd" +
                                    (!_vm.loading &&
                                    _vm.form.tipoTelefone == "CELULAR"
                                      ? "|cellphone"
                                      : ""),
                                  id: "telefoneCelular",
                                  name: "Telefone",
                                  label: "Telefone *",
                                  clearable: "",
                                  outlined: "",
                                  "persistent-hint": "",
                                  "data-test-id": "telefone",
                                },
                                model: {
                                  value: _vm.form.telefone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "telefone", $$v)
                                  },
                                  expression: "form.telefone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("base-text-field", {
                        attrs: {
                          id: "anotacao",
                          name: "anotacoes",
                          label: "Anotações",
                          clearable: "",
                          outlined: "",
                          "data-test-id": "anotações",
                        },
                        model: {
                          value: _vm.form.anotacao,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "anotacao", $$v)
                          },
                          expression: "form.anotacao",
                        },
                      }),
                      _c(
                        "v-row",
                        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-left" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    "data-test-id": "voltar",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("back")
                                    },
                                  },
                                },
                                [_vm._v(" VOLTAR ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secundaryBtn--text",
                                  attrs: {
                                    type: "submit",
                                    elevation: "10",
                                    color: "primary",
                                    loading: _vm.loading,
                                    "data-test-id": "salvar",
                                  },
                                },
                                [_vm._v(" SALVAR ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }