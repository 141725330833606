<template>
    <div>
      <v-app-bar color="primary" dark fixed>
        <v-container class="d-flex align-center pa-0">
          <v-btn class="mr-3" small icon dark @click="$emit('back')">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
  
          <v-toolbar-title class="mt-1">
            <div>
              <h1 class="title font-weight-bold mb-1 title" style="line-height: 1">Criar Proposta</h1>
              <h3 class="caption ma-0" style="line-height: 1">Dados corretora</h3>
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small icon dark @click="$emit('openmenu')">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-container>
      </v-app-bar>
      <div class="header-height"></div>
      <v-container class="pa-5 pt-0">
        <div class="d-flex justify-center mt-10 pa-10" v-if="loading">
          <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
        </div>
        <validation-observer ref="form" tag="form" class="mb-10" @submit.prevent="submit" v-else>
          <v-row justify="center">
            <v-col class="col-12 col-md-6">
              <v-row>
                <v-col class="pb-0">
                  <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Corretora</h2>
                  <v-divider></v-divider>
                  <validation-provider name="Corretora" vid="corretora" v-slot="{ errors }" rules="required">
                    <v-radio-group :value="form.corretora" :value-comparator="valueCompare" :mandatory="false" :error-messages="errors">
                      <div v-if="corretoras.length > 0">
                        <template v-for="(corretora, index) in corretoras">
                          <v-radio
                            :data-test-id="'corretora-' + index"
                            :id="'corretora-' + index"
                            class="mb-4"
                            :class="{ 'mt-4': index > 0 }"
                            :label="corretora.nomeFantasia"
                            :value="corretora.cpfCnpj"
                            :key="corretora.cpfCnpj"
                            @click="handleClickCorretora(corretora)"
                          ></v-radio>
                          <v-divider :key="corretora.nomeFantasia" v-if="index < corretoras.length - 1"></v-divider>
                        </template>
                      </div>
                      <div v-else>
                        Aguardando aprovação de corretora
                      </div>
                    </v-radio-group>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-btn
                    :data-test-id="'salvar-continuar-1'"
                    :id="'salvar-continuar'"
                    :loading="loadingSalvar"
                    :disabled="!form.corretora"
                    type="submit"
                    elevation="10"
                    block
                    color="primary"
                    class="secundaryBtn--text mt-4"
                    >Salvar e continuar</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import corretoraService from "@/services/corretoraService";
  import propostaService from "@/services/propostaService";
  import AsyncSupervisores from "@/components/AsyncSupervisores.vue";
  import BaseWrapperDialogList from "@/components/BaseWrapperDialogList.vue";
  import BaseTextField from "@/components/BaseTextField.vue";
  
  export default {
    name: "Corretoras",
    components: {
      AsyncSupervisores,
      BaseWrapperDialogList,
      BaseTextField,
    },
    data() {
      return {
        loading: false,
        loadingSalvar: false,
        corretoras: [],
        supervisores: [],
        form: {
          administradora: "qualicorp",
          corretora: null,
          supervisor: null,
          portabilidade: null,
          sumula: null,
          corretoraRepasse: null,
          sumulaPlanoAnterior: null,
        },
      };
    },
    computed: {
      ...mapState({
        user: (state) => state.user,
        cpf: (state) => state.user.profile.cpf,
        corretora: (state) => state.simulacao.corretora,
      }),
      selectedCorretora() {
        return this.corretoras.find((item) => item.cpfCnpj === this.form.corretora);
      },
    },
    methods: {
      ...mapActions({
        setCorretora: "simulacao/setCorretora"
      }),
      handleClickCorretora(corretora) {
        this.form.corretora = corretora.cpfCnpj
        if (corretora.tipoCorretora === this.user.profile.tipoCorretor) {
          this.form.corretora = corretora.cpfCnpj
        } else {
          this.form.corretora = null
          this.$root.$snackBar.open({
            color: "error",
            message: "Não é possível seguir com a corretora selecionada",
          });
        }
      },
      async getCorretoras() {
        try {
          this.loadingSalvar = true;
          const data = await corretoraService.getCorretorasPorCpf({
            cpf: this.cpf,
          });
  
          this.corretoras = data;
          if(this.corretoras.length === 1) {
            this.setCorretora(this.corretoras[0])
            this.$emit("next");
          }
        } catch (error) {
            this.$root.$snackBar.open({
            color: "error",
            message: error.message || "Erro ao listar corretoras, tente novamente mais tarde",
          });
        } finally {
          this.loadingSalvar = false;
        }
      },
      async submit() {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        const corretora = this.selectedCorretora
          
        try {
          this.loadingSalvar = true;
          this.setCorretora(corretora);
          this.$emit("next");
        } catch (error) {
          this.$root.$snackBar.open({
            color: "error",
            message: error.message || "Erro ao salvar dados, tente novamente mais tarde",
          });
        } finally {
          this.loadingSalvar = false;
        }
      },
      valueCompare(a, b) {
        return Object.is(a, b);
      },
    },
    created() {
      this.getCorretoras();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .header-height {
    padding: 20px;
    height: 70px;
  }
  </style>
  