import { render, staticRenderFns } from "./BaseNewCardDetailsPlan.vue?vue&type=template&id=56d5e6ce&scoped=true"
import script from "./BaseNewCardDetailsPlan.vue?vue&type=script&lang=js"
export * from "./BaseNewCardDetailsPlan.vue?vue&type=script&lang=js"
import style0 from "./BaseNewCardDetailsPlan.vue?vue&type=style&index=0&id=56d5e6ce&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d5e6ce",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VDivider,VIcon,VImg,VListItemAvatar,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d5e6ce')) {
      api.createRecord('56d5e6ce', component.options)
    } else {
      api.reload('56d5e6ce', component.options)
    }
    module.hot.accept("./BaseNewCardDetailsPlan.vue?vue&type=template&id=56d5e6ce&scoped=true", function () {
      api.rerender('56d5e6ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/simulacoes/BaseNewCardDetailsPlan.vue"
export default component.exports