<template>
  <div>
  <v-dialog
    no-click-animation
    v-model="value"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile v-if="plano">
      <the-nav-bar title="Detalhe plano" @back="close"></the-nav-bar>
      <v-container class="pa-5 pt-0">
        <v-row justify="center">
          <v-col xs="12" sm="10" md="8" lg="6" xl="6">
            <v-row>
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Resumo</h2>
                <v-divider></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Plano</v-list-item-title>
                    <v-list-item-subtitle
                      class="subtitle-1 text-uppercase text-wrap"
                      style="line-height: 1.2;"
                    >{{plano.nomePlanoAns}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Operadora</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{plano.operadora}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 font-weight-bold mb-0">Qtd. Beneficiário</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1 text-uppercase">{{beneficiarios.length}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row v-if="plano && plano.coparticipacao && tabelaCoparticipacao">
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Coparticipação</h2>
                <v-divider></v-divider>
                <v-btn
                  large
                  elevation="10"
                  block
                  color="primary"
                  class="text-none mt-4"
                  @click="modalCoparticipacao = true"
                >
                  Consulte Valores
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 mt-4">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Beneficiários</h2>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" v-for="(beneficiario, index) in beneficiarios" :key="index">
                    <base-card-beneficiary
                      :nome="beneficiario.chave || calcularFaixaEtaria(beneficiario.dataNascimento)"
                      :valor="beneficiario.valorContratual"
                      :valorSemDesconto="beneficiario.valorContratualSemDesconto"
                      :tipo="''"
                      :avatar="`${index + 1}`"
                      :valorOdonto="beneficiario.valorOdonto"
                      :comOdonto="plano.comOdonto"
                    ></base-card-beneficiary>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="tipo === 'simulacao'">
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Informações Básicas</h2>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12">
                    Plano coletivo por adesão, sendo necessário o vínculo profissional, classista ou setorial com uma entidade de classe que mantém convênio com a Qualicorp.<br/>
                    Para conhecer as condições contratuais de adesão, ligue para 0800 777 4004
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="tipo === 'simulacao'">
              <v-col class="pb-0">
                <v-btn color="primary" outlined block @click="dialogRede = true" class="my-5">
                  <span class="mt-1 subtitle-2">Rede médica</span>
                </v-btn>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12">
                    <rede-referenciada
                      v-model="dialogRede"
                      :idProdutoFatura="plano.idProdutoFatura"
                      :uf="plano.uf"
                      @close="dialogRede = false"
                    >
                    </rede-referenciada>
                  </v-col>
                </v-row>
                <div>
                  <template v-for="(item, index) in prestadorasFiltradas">
                    <v-list-item :key="index">
                      <v-list-item-content>
                        <strong>{{ item.prestador }}</strong><br/>
                        <div class="tags mt-1">
                          <span><v-chip small color="primary" label class="d-inline">{{ item.tipoPrestador }}</v-chip></span>
                          <div class="mt-2">
                            <span class="mr-1" v-for="(tag, index) in item.tipoAtendimento" :key="index">
                              <v-chip small color="grey lighten-1" v-if="tag !== 'LAB'">
                                {{ getTipoAtendimento(tag) }}
                              </v-chip>
                            </span>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < prestadorasFiltradas.length - 1"
                      :key="`divider-${index}`"
                    ></v-divider>
                  </template>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="tipo === 'simulacao'">
              <v-col class="pb-0">
                <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Reajuste anual</h2>
                <v-list-item-subtitle class="subtitle-1 text-uppercase">{{meses[plano.mesReajuste]}}</v-list-item-subtitle>
              </v-col>
            </v-row>
            <detalhes-produtos-opcionais
              v-if="tipo === 'simulacao' && (plano && plano.possuiPlanosOpcionais)"
              :produtosOpcionais="produtosOpcionais"
              :titular="titular"
              :loading="loadingProdutosOpcionais"
            />
            <v-row no-gutters v-if="tipo === 'proposta'">
              <v-col>
                <v-btn
                  large
                  type="submit"
                  elevation="10"
                  block
                  color="primary"
                  class="secundaryBtn--text mt-4"
                  @click.prevent="$emit('selecionarplano', plano)"
                >Selecionar plano</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
  <v-dialog v-model="modalCoparticipacao" max-width="500">
    <v-card>
      <v-card-title class="headline">
        <div class="modal-title">
          Coparticipação
        </div>
      </v-card-title>
      <v-card-text>
        <table>
          <thead>
            <tr>
              <th scope="col">Procedimentos</th>
              <th scope="col">Valor (R$)</th>
              <th scope="col">Valor (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tabelaCoparticipacao" :key="index">
              <td :style="{ width: '250px' }"> {{ item.procedimento }} </td>
              <td :style="{ width: '100px', textAlign: 'center' }"> {{ item.tipoValor === "R$" ? item.valor : "-"}} </td>
              <td :style="{ width: '100px', textAlign: 'center' }"> {{ item.tipoValor === "%" ? item.valor : "-" }} </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-divider class="mt-6"></v-divider>
      <v-card-text class="mt-2">
        As informações apresentadas acima podem ser alteradas pela operadora a qualquer momento.
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import BaseCardBeneficiary from '@/components/BaseCardBeneficiary.vue';
import BaseAutocompleteField from '@/components/BaseAutocompleteField.vue';
import DetalhesProdutosOpcionais from '@/components/simulacoes/DetalhesProdutosOpcionais.vue';
import redeService from '@/services/redeReferenciadaService';
import TheNavBar from '@/components/TheNavBar.vue';
import { mapState, mapActions } from 'vuex';
import Meses from "@/utils/constants/Meses";
import filters from '@/filters';
import moment from 'moment';
import planoService from '@/services/planoService';
import RedeReferenciada from '@/views/AreaLogada/Simulacoes/Criar/RedeReferenciada.vue';
import fatoresModeradoresService from '@/services/fatoresModeradoresService'

export default {
  name: 'DialogPlano',
  components: {
    BaseCardBeneficiary,
    TheNavBar,
    BaseAutocompleteField,
    DetalhesProdutosOpcionais,
    RedeReferenciada
  },
  data() {
    return {
      cidades: [],
      dialogRede: false,
      prestadores: [],
      meses: Meses,
      prestadorasFiltradas: [],
      form: {},
      produtosOpcionais: [],
      loadingProdutosOpcionais: false,
      tabelaCoparticipacao: [],
      modalCoparticipacao: false,
    };
  },
  props: {
    plano: {
      required: true,
    },
    value: {
      default: false,
    },
    titular: {
      default: {},
    },
    tipo: {
      default: '',
    },
    endereco: {
      default: {},
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.getProdutosOpcionais();
      }
    },
    async plano(newValue) {
      if (newValue && newValue.coparticipacao) {
        const dateNow = new Date(new Date().getFullYear(), new Date().getMonth() + 1, newValue.diaInicioVigencia)
        const dateFormatted = moment(dateNow).format().split("T")[0]
        const response = await fatoresModeradoresService.getTabelaCoparticipacao({idProduto: newValue.idProdutoFatura, vigencia: dateFormatted})
        this.tabelaCoparticipacao = response.data
      }
    },
  },
  computed: {
    beneficiarios() {
      this.redeMedica();
      return this.plano.beneficiarios.map((m) => {
        m.dataNascimento = filters.convertDateInput(m.dataNascimento);
        m.valorOdonto = (parseFloat(m.valorContratual) + parseFloat(m.valorOdontoAdicional));
        return m;
      });
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    
    isTipo(beneficiario) {
      return beneficiario.dataNascimento === this.titular.dataNascimento ? 'Titular' : 'Dependente';
    },

    calcularFaixaEtaria(data) {
      return filters.calcularFaixaEtaria(data)
    },

    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    async redeMedica() {
      try {
        const data = await redeService.getRedeReferenciada({
          idProdutoFatura: [this.plano.idProdutoFatura], UF: this.plano.uf,
        });
        const { cidades, prestadores } = data.data;
        this.cidades = cidades.sort((a, b) => a.localeCompare(b));
        this.prestadores = prestadores;
      } catch (error) {
        this.cidades = [];
        this.prestadores = [];
      }
    },
    getTipoAtendimento(tag) {
      switch (tag.toUpperCase()) {
      case 'INT':
        return 'Internação';
      case 'MAT':
        return 'Maternidade';
      case 'PS':
        return 'Pronto Socorro';
      default:
        return tag;
      }
    },
    filtrarPrestadores(value) {
      const prestadores = this.prestadores.filter((p) => p.cidade === value);
      this.prestadorasFiltradas = prestadores.sort((a, b) => a.prestador.localeCompare(b.prestador));
    },
    async getProdutosOpcionais() {
      try {
        this.loadingProdutosOpcionais = true;
        const clonePlano = { ...this.plano };
        const { beneficiarios } = clonePlano;
        const body = {
          beneficiarios: beneficiarios.map((item) => (
            {
              chave: item.chave,
              dataNascimento: item.dataNascimento,
              idade: item.idade,
              valorContratual: item.valorContratual,
            }
          )),
          uf: this.endereco.estado,
          cidade: this.endereco.cidade,
        };
        const data = await planoService.getProdutosOpcionais({ idProdutoFatura: this.plano.idProdutoFatura, body });
        this.produtosOpcionais = [...data];
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      } finally {
        this.loadingProdutosOpcionais = false;
      }
    },
  },
};
</script>
