<template>
  <div>
    <v-container class="mt-5">
      <v-row justify="center">
        <v-col class="col-12 col-md-6">
          <h3 class="font-weight-bold">
            Preencha os dados de seu cliente:
          </h3>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col class="col-12 col-md-6">
          <validation-observer tag="form" ref="form" class="mb-10" @submit.prevent="submit" autocomplete="off">
            <v-row>
              <v-col class="pb-0">
                <p class="mb-7">
                  Para começar, informe o CEP do titular:
                </p>
                <async-cep-residencial :cep="form.cep" v-slot="{ loading: loadingCep }" @success="successCep($event)" @error="errorCep()">
                  <base-text-field
                    :autoBlur="9"
                    rules="required"
                    inputmode="numeric"
                    type="tel"
                    id="cep"
                    name="CEP"
                    label="CEP"
                    clearable
                    v-mask="'#####-###'"
                    outlined
                    maxlength="9"
                    v-model="form.cep"
                    :loading="loadingCep"
                  ></base-text-field>
                </async-cep-residencial>

                <div v-if="showProfissao">
                  <p class="mb-7">
                    selecione a profissão/entidade:
                  </p>
                  <div style="display: flex; flex-direction: row; width: 100%">
                    <async-profissoes
                      style="width: 100%"
                      :key="'c_profissao-' + keyProfissao"
                      :administradora="[dadosAdministradora]"
                      :cidade="endereco.cidade"
                      :estado="endereco.estado"
                      :entidades="entidades"
                      v-slot="{ loading: loadingProfissoes, data }"
                    >
                      <base-autocomplete-field
                        :items="data"
                        id="profissao"
                        name="profissão"
                        label="Profissão"
                        v-model="form.publicoAlvo"
                        mode="aggressive"
                        :disabled="!endereco.cidade || !endereco.estado"
                        :item-text="'nome'"
                        :loading="loadingProfissoes"
                        outlined
                        @input="selectProfissao($event)"
                        chips
                        data-test-id="profissão"
                      >
                      </base-autocomplete-field>
                    </async-profissoes>
                    <span style="padding: 10px; line-height: 2.0; display: inline-block;">ou</span>
                    <async-entidades
                      style="width: 100%"
                      :fullList="true"
                      :key="'c_entidade-' + keyEntidade"
                      :administradora="[dadosAdministradora]"
                      :cidade="endereco.cidade"
                      :estado="endereco.estado"
                      :publico-alvo="form.publicoAlvo"
                      v-slot="{ loading: loading, data }"
                    >
                      <base-autocomplete-field
                        :items="data"
                        id="entidade"
                        name="Entidade"
                        label="Entidade"
                        v-model="form.entidades"
                        mode="aggressive"
                        :disabled="!endereco.cidade || !endereco.estado"
                        item-text="id"
                        :loading="loading"
                        data-test-id="entidade"
                        outlined
                        @input="keyProfissao++"
                        multiple
                        chips
                        return-object
                      >
                        <template v-slot:selection="data">
                          <v-chip v-bind="data.attrs" :input-value="data.selected" close @click:close="removerEntidade(data.item.id)"> </v-chip>
                        </template>
                      </base-autocomplete-field>
                    </async-entidades>
                  </div>
                  <div style="text-align: right;">
                    <span @click="limparFiltros" style="color: #171a88; cursor: pointer; text-align: left;">Limpar filtros</span>
                  </div>
                </div>
                <v-divider style="margin-bottom: 12px;"></v-divider>
                <div v-if="form.publicoAlvo || (form.entidades && form.entidades.length)">
                  <p class="mb-7">
                    Qual a faixa etária dos beneficiários?
                  </p>
                  <v-row class="grid-container">
                    <v-col v-for="item in dataNascimento" :key="item.id">
                      <span>{{ item.texto }}</span>
                      <v-text-field
                        id="dataNascimento"
                        v-model="item.count"
                        append-icon='mdi-minus'
                        append-outer-icon='mdi-plus'
                        @click:append="decreaseData(item)"
                        @click:append-outer="increaseData(item)"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <base-text-field
                    value="teste"
                    rules="required|date"
                    id="dataNascimento"
                    name="Data de nascimento"
                    label="Data de nascimento *"
                    placeholder="DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    inputmode="numeric"
                    clearable
                    v-model="form.dataNascimento"
                    mode="aggressive"
                    outlined
                    data-test-id="data-de-nascimento"
                  ></base-text-field> -->
                </div>
              </v-col>
            </v-row>
            <!-- <v-row v-if="form.dataNascimento" no-gutters class="mb-4">
              <v-col>
                <div class="d-flex align-center mb-2">
                  <h2 class="primary--text subtitle-1 font-weight-bold">Dependentes</h2>
                  <v-spacer></v-spacer>
                  <v-btn icon color="primary" :disabled="dependentes.length >= 5" @click.prevent="addDependente" data-test-id="adicionar-dependente">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!dependentes" class="mb-5">
              <v-col>
                <span class="d-flex justify-center subtitle-2 grey--text font-weight-regular">Nenhum cadastrado</span>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <div class="d-flex align-center" v-for="(item, index) in dependentes" :key="index">
                  <base-text-field
                    :autoBlur="10"
                    rules="required|date"
                    inputmode="numeric"
                    type="tel"
                    :id="`dependente_${index + 1}`"
                    :name="`Dependente ${index + 1}`"
                    :label="`Data de nascimento ${$vuetify.breakpoint.smAndUp ? 'do dependente' : 'do dep.'} ${index + 1}`"
                    clearable
                    v-mask="'##/##/####'"
                    placeholder="dd/mm/aaaa"
                    outlined
                    v-model="dependentes[index].dataNascimento"
                  ></base-text-field>
                  <v-btn icon class="mb-7 ml-4" color="#F44F45" @click.prevent="removeDependente(index)" :data-test-id="`remover-dependente-${index}`">
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row> -->
            <v-row v-if="showBotaoContinuar" no-gutters>
              <v-col class="text-right">
                <v-btn type="submit" elevation="10" color="primary" class="secundaryBtn--text" :loading="loading" data-test-id="salvar-e-continuar-1">
                  CONTINUAR
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AsyncCepResidencial from "@/components/AsyncCepResidencial.vue";
import AsyncEntidades from "@/components/AsyncEntidades.vue";
import AsyncProfissoes from "@/components/AsyncProfissoes.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import BaseAutocompleteField from "@/components/BaseAutocompleteField.vue";
import { mapActions, mapState } from "vuex";
import filters from "@/filters";
import corretoraService from "@/services/corretoraService";
import moment from 'moment';

export default {
  name: "DadosPessoais",
  components: {
    AsyncCepResidencial,
    AsyncProfissoes,
    AsyncEntidades,
    BaseTextField,
    BaseAutocompleteField,
  },
  data() {
    return {
      loading: false,
      loadingCpf: false,
      keyProfissao: 0,
      keyEntidade: 0,
      endereco: {},
      dependentes: [],
      corretoras: [],
      form: {
        cep: "",
      },
      showProfissao: false,
      flagDependente: false,
      dataNascimento: [
        { id: 1, texto: '00 a 18', count: 0, min: 0, max: 18 },
        { id: 2, texto: '19 a 23', count: 0 ,min: 19, max: 23 },
        { id: 3, texto: '24 a 28', count: 0 ,min: 24, max: 28 },
        { id: 4, texto: '29 a 33', count: 0 ,min: 29, max: 33 },
        { id: 5, texto: '34 a 38', count: 0 ,min: 34, max: 38 },
        { id: 6, texto: '39 a 43', count: 0 ,min: 39, max: 43 },
        { id: 7, texto: '44 a 48', count: 0 ,min: 44, max: 48 },
        { id: 8, texto: '49 a 53', count: 0 ,min: 49, max: 53 },
        { id: 9, texto: '54 a 58', count: 0 ,min: 54, max: 58 },
        { id: 10, texto: '59 ou +', count: 0 ,min: 59, max: 99 },
      ],
      auxDataNascimento: []
    };
  },
  async created() {
    await this.getCorretoras();
    this.getAdministradora();
    if (this.form.cep) this.showProfissao = true;
    if (this.dependentes.length) this.flagDependente = true;
  },
  computed: {
    ...mapState({
      dadosAdministradora: (state) => state.simulacao.administradora,
      dadosTitular: (state) => state.simulacao.titular,
      dadosDependentes: (state) => state.simulacao.dependentes,
      dadosEndereco: (state) => state.simulacao.endereco,
      cpf: (state) => state.user.profile.cpf,
    }),
    /* eslint-disable */
    showBotaoContinuar() {
      const dataNascimento = this.auxDataNascimento ? this.auxDataNascimento.length : 0
      const { cep, publicoAlvo, entidades } = this.form;
      if (cep && (publicoAlvo || entidades && entidades.length) && dataNascimento > 0) return true;
      return false;
    },
    /* eslint-enable */
    entidades() {
      return this.form.entidades && this.form.entidades.length ? this.form.entidades.map(e => e.id ) : []
    }
  },
  watch: {
    dadosTitular: {
      immediate: true,
      handler: "setDataTitular",
      deep: true,
    },
    dadosEndereco: {
      immediate: true,
      handler: "setDataEndereco",
      deep: true,
    },
    dadosDependentes: {
      immediate: true,
      handler: "setDataDependente",
      deep: true,
    },
    flagDependente(flag) {
      if (!flag) this.dependentes = [];
    },
  },
  methods: {
    ...mapActions({
      setTitular: "simulacao/setTitular",
      setDependentes: "simulacao/setDependentes",
      setEndereco: "simulacao/setEndereco",
      setAdministradora: "simulacao/setAdministradora",
    }),
    removerEntidade(data) {
      this.form.entidades = this.form.entidades.filter((entidade) => entidade.id !== data.id);
    },
    setDataTitular() {
      if (!this.loading) {
        if (!Object.keys(this.dadosTitular).length) return;
        const titular = this.$cleanObserver(this.dadosTitular);
        this.form = titular;
        if (this.form.dataNascimento) {
          this.form.dataNascimento = filters.convertDateOutput(this.form.dataNascimento);
        }
        if (!this.dadosTitular.entidades) this.form.entidades = [{ id: this.form.entidade, nome: this.form.entidade }];
        this.cep = this.endereco.cep || "";
      }
    },
    setDataEndereco() {
      this.endereco = this.dadosEndereco;
      if (this.dadosEndereco.cep) this.form.cep = this.dadosEndereco.cep;
    },
    setDataDependente() {
      let dependentes = this.$cleanObserver(this.dadosDependentes);
      dependentes = dependentes.map((dependente) => ({
        dataNascimento: filters.convertDateOutput(dependente.dataNascimento),
        possuiPlano: false,
        aceitouNaoReduzirCarencia: false,
      }));
      this.dependentes = dependentes;
    },
    addDependente() {
      if (this.enabledAddDependente()) {
        this.$set(this.dependentes, this.dependentes.length, {
          dataNascimento: "",
          possuiPlano: false,
          aceitouNaoReduzirCarencia: false,
        });
      }
    },
    enabledAddDependente() {
      return true
    },
    removeDependente(index) {
      this.dependentes.splice(index, 1);
    },
    async successCep(data) {
      data.tipo = ["ENDERECO_RESIDENCIAL"];
      data.numero = "";
      data.complemento = "";
      this.endereco = data;
      this.keyEntidade++;
      this.keyProfissao++;
      this.showProfissao = true;
    },
    errorCep() {
      this.showProfissao = false;
    },
    async selectProfissao(data) {
      this.$set(this.form, "publicoAlvo", data);
      this.keyEntidade++;
    },
    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      if (!this.form.dataNascimento || this.form.dataNascimento === '') {
        this.$root.$snackBar.open({
          color: 'error',
          message: 'Informar a faixa etária dos beneficiários',
        });
        return
      } 
      const titular = {
        ...this.dadosTitular,
        dataNascimento: filters.convertDateInput(this.form.dataNascimento),
        entidades: this.form.entidades && this.form.entidades.length ? this.form.entidades.filter(e => !!e) : [],
        publicoAlvo: this.form.publicoAlvo,
      };
      const endereco = {
        cep: this.endereco.cep,
        cidade: this.endereco.cidade,
        estado: this.endereco.estado,
      };
      const dependentes = this.dependentes.map((dependente) => ({
        dataNascimento: filters.convertDateInput(dependente.dataNascimento),
        possuiPlano: false,
        aceitouNaoReduzirCarencia: false,
      }));
      this.setTitular({ titular });
      this.setEndereco({ endereco });
      this.setDependentes({ dependentes });
      this.$emit("next");
    },
    getAdministradora() {
      let administradoras = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const corretora of this.corretoras) {
        const admsCorretora = corretora.administradoras || [];
        administradoras = [...administradoras, ...admsCorretora];
      }
      this.setAdministradora({ administradora: administradoras });
    },
    limparFiltros() {
      this.form.publicoAlvo = ""
      this.form.entidades = []
      this.keyEntidade++
      this.keyProfissao++
    },
    async getCorretoras() {
      try {
        this.loading = true;
        const data = await corretoraService.getCorretorasPorCpf({
          cpf: this.cpf,
        });
        this.corretoras = data;
      } catch (error) {
        this.$root.$snackBar.open({ color: "error", message: "Erro ao buscar corretoras." });
      } finally {
        this.loading = false;
      }
    },
    getDataMedia(item) {
      const dataAtual = moment();
      const dataNascimentoMedia = dataAtual.subtract(Math.floor((item.min + item.max) / 2), 'years');
      let dataMedia = dataNascimentoMedia.format('DD/MM/YYYY');
      return dataMedia
    },
    separarTitularDependetes() {
      let lengthData = this.auxDataNascimento.length
      if (lengthData === 1) {
        this.form.dataNascimento = this.auxDataNascimento[0]
        this.dependentes = []
      } else {
        this.form.dataNascimento = this.auxDataNascimento[0]
        this.dependentes = []
        for (let item of this.auxDataNascimento.slice(1,lengthData)) {
          let obj = {
            dataNascimento: item,
            possuiPlano: false,
            aceitouNaoReduzirCarencia: false,
          }
          this.dependentes.push(obj)
        }
      }
    },
    increaseData(item) {
      this.dataNascimento.map(x => {
        if (x.id === item.id) {
          x.count++
          this.auxDataNascimento.push(this.getDataMedia(item))
          this.separarTitularDependetes()
        }
      })
    },
    decreaseData(item) {
      this.dataNascimento.map(x => {
        if (x.id === item.id && x.count > 0) {
          x.count--
          this.auxDataNascimento.splice(this.auxDataNascimento.indexOf(this.getDataMedia(item)), 1)
          this.separarTitularDependetes()
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.header-height {
  padding: 20px;
  height: 56px;
  @media (min-width: 960px) {
    height: 70px;
  }
}
.grid-container {
  display: grid;
  @media (max-width: $media-md) {
    grid-template-columns: auto auto;
  }
  @media (min-width: $media-md) and (max-width: $media-lg) {
    grid-template-columns: auto auto auto auto;
  }
  @media (min-width: $media-lg) {
    grid-template-columns: auto auto auto auto auto;
  }
  padding: 10px;
}
</style>
