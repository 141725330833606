<template>
  <div>
    <v-toolbar dark dense :color="$store.getters.getColor('primary')" fixed>
      <v-btn @click="back" icon data-test-id="voltar">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="espacamento-linhas pt-1">
        <div class="page-title" v-if="editando">Editar Simulação</div>
        <div class="page-title" v-else>Criar Simulação</div>
        <div class="page-step">{{ pages[step].nome }}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="step === 2" small icon dark class="mr-4" @click="buscar" data-test-id="buscar">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon data-test-id="atualizar" @click="atualizar">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-toolbar>
    <component ref="component" :is="pages[step].component" @next="next" @back="back" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DadosPessoais from "./DadosPessoais.vue";
import Planos from "./Planos.vue";
import Registro from "./Registro.vue";
import Corretoras from "./Corretoras.vue";
export default {
  name: "Criar-Simulacao",
  data() {
    return {
      pages: [
        { nome: "Associar corretora", component: Corretoras },
        { nome: "Dados Pessoais", component: DadosPessoais },
        { nome: "Planos", component: Planos },
        { nome: "Registro", component: Registro },
      ],
      dialogPlano: false,
      steppers: ["Informações", "Planos", "Registro"],
      step: 0,
    };
  },
  computed: {
    ...mapState({
      editando: (state) => state.simulacao.editando,
    }),
  },
  methods: {
    next() {
      this.step++;
    },
    back() {
      if (this.step === 0) this.$router.push({ name: "areaLogada.simulacoes" });
      else this.step--;
    },
    buscar() {
      this.$refs.component.abrirBusca();
    },
    atualizar() {
      this.$refs.component.getLista();
    },
  },
};
</script>

<style lang="scss">
.page-step {
  line-height: 1.1rem;
  font-size: 0.9rem;
}

.page-title {
  line-height: 1rem;
  padding: 5px 0 0 0;
}
</style>
