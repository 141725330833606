var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", dark: "", fixed: "" } },
        [
          _c(
            "v-container",
            { staticClass: "d-flex align-center pa-0" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-3",
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                1
              ),
              _c("v-toolbar-title", { staticClass: "mt-1" }, [
                _c("div", [
                  _c(
                    "h1",
                    {
                      staticClass: "title font-weight-bold mb-1 title",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Criar Proposta")]
                  ),
                  _c(
                    "h3",
                    {
                      staticClass: "caption ma-0",
                      staticStyle: { "line-height": "1" },
                    },
                    [_vm._v("Dados corretora")]
                  ),
                ]),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { small: "", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openmenu")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "header-height" }),
      _c(
        "v-container",
        { staticClass: "pa-5 pt-0" },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "d-flex justify-center mt-10 pa-10" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "grey", size: "50" },
                  }),
                ],
                1
              )
            : _c(
                "validation-observer",
                {
                  ref: "form",
                  staticClass: "mb-10",
                  attrs: { tag: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "primary--text mb-2 subtitle-1 font-weight-bold",
                                    },
                                    [_vm._v("Corretora")]
                                  ),
                                  _c("v-divider"),
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Corretora",
                                      vid: "corretora",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function ({ errors }) {
                                          return [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  value: _vm.form.corretora,
                                                  "value-comparator":
                                                    _vm.valueCompare,
                                                  mandatory: false,
                                                  "error-messages": errors,
                                                },
                                              },
                                              [
                                                _vm.corretoras.length > 0
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _vm._l(
                                                          _vm.corretoras,
                                                          function (
                                                            corretora,
                                                            index
                                                          ) {
                                                            return [
                                                              _c("v-radio", {
                                                                key: corretora.cpfCnpj,
                                                                staticClass:
                                                                  "mb-4",
                                                                class: {
                                                                  "mt-4":
                                                                    index > 0,
                                                                },
                                                                attrs: {
                                                                  "data-test-id":
                                                                    "corretora-" +
                                                                    index,
                                                                  id:
                                                                    "corretora-" +
                                                                    index,
                                                                  label:
                                                                    corretora.nomeFantasia,
                                                                  value:
                                                                    corretora.cpfCnpj,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleClickCorretora(
                                                                        corretora
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              index <
                                                              _vm.corretoras
                                                                .length -
                                                                1
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      key: corretora.nomeFantasia,
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _c("div", [
                                                      _vm._v(
                                                        " Aguardando aprovação de corretora "
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secundaryBtn--text mt-4",
                                      attrs: {
                                        "data-test-id": "salvar-continuar-1",
                                        id: "salvar-continuar",
                                        loading: _vm.loadingSalvar,
                                        disabled: !_vm.form.corretora,
                                        type: "submit",
                                        elevation: "10",
                                        block: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("Salvar e continuar")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }