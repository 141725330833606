var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "no-click-animation": "",
            persistent: "",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _vm.plano
            ? _c(
                "v-card",
                { attrs: { tile: "" } },
                [
                  _c("the-nav-bar", {
                    attrs: { title: "Detalhe plano" },
                    on: { back: _vm.close },
                  }),
                  _c(
                    "v-container",
                    { staticClass: "pa-5 pt-0" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "10",
                                md: "8",
                                lg: "6",
                                xl: "6",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-0" },
                                    [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "primary--text mb-2 subtitle-1 font-weight-bold",
                                        },
                                        [_vm._v("Resumo")]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold mb-0",
                                                },
                                                [_vm._v("Plano")]
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "subtitle-1 text-uppercase text-wrap",
                                                  staticStyle: {
                                                    "line-height": "1.2",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.plano.nomePlanoAns
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold mb-0",
                                                },
                                                [_vm._v("Operadora")]
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "subtitle-1 text-uppercase",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.plano.operadora)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                {
                                                  staticClass:
                                                    "subtitle-2 font-weight-bold mb-0",
                                                },
                                                [_vm._v("Qtd. Beneficiário")]
                                              ),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "subtitle-1 text-uppercase",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.beneficiarios.length
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.plano &&
                              _vm.plano.coparticipacao &&
                              _vm.tabelaCoparticipacao
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v("Coparticipação")]
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "text-none mt-4",
                                              attrs: {
                                                large: "",
                                                elevation: "10",
                                                block: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.modalCoparticipacao = true
                                                },
                                              },
                                            },
                                            [_vm._v(" Consulte Valores ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pb-0 mt-4" },
                                    [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "primary--text mb-2 subtitle-1 font-weight-bold",
                                        },
                                        [_vm._v("Beneficiários")]
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-row",
                                        _vm._l(
                                          _vm.beneficiarios,
                                          function (beneficiario, index) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: index,
                                                attrs: { cols: "12" },
                                              },
                                              [
                                                _c("base-card-beneficiary", {
                                                  attrs: {
                                                    nome:
                                                      beneficiario.chave ||
                                                      _vm.calcularFaixaEtaria(
                                                        beneficiario.dataNascimento
                                                      ),
                                                    valor:
                                                      beneficiario.valorContratual,
                                                    valorSemDesconto:
                                                      beneficiario.valorContratualSemDesconto,
                                                    tipo: "",
                                                    avatar: `${index + 1}`,
                                                    valorOdonto:
                                                      beneficiario.valorOdonto,
                                                    comOdonto:
                                                      _vm.plano.comOdonto,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.tipo === "simulacao"
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v("Informações Básicas")]
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _vm._v(
                                                    " Plano coletivo por adesão, sendo necessário o vínculo profissional, classista ou setorial com uma entidade de classe que mantém convênio com a Qualicorp."
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " Para conhecer as condições contratuais de adesão, ligue para 0800 777 4004 "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.tipo === "simulacao"
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "my-5",
                                              attrs: {
                                                color: "primary",
                                                outlined: "",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialogRede = true
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mt-1 subtitle-2",
                                                },
                                                [_vm._v("Rede médica")]
                                              ),
                                            ]
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("rede-referenciada", {
                                                    attrs: {
                                                      idProdutoFatura:
                                                        _vm.plano
                                                          .idProdutoFatura,
                                                      uf: _vm.plano.uf,
                                                    },
                                                    on: {
                                                      close: function ($event) {
                                                        _vm.dialogRede = false
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.dialogRede,
                                                      callback: function ($$v) {
                                                        _vm.dialogRede = $$v
                                                      },
                                                      expression: "dialogRede",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _vm._l(
                                                _vm.prestadorasFiltradas,
                                                function (item, index) {
                                                  return [
                                                    _c(
                                                      "v-list-item",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.prestador
                                                                )
                                                              ),
                                                            ]),
                                                            _c("br"),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "tags mt-1",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  [
                                                                    _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "d-inline",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "primary",
                                                                          label:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.tipoPrestador
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                  },
                                                                  _vm._l(
                                                                    item.tipoAtendimento,
                                                                    function (
                                                                      tag,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key: index,
                                                                          staticClass:
                                                                            "mr-1",
                                                                        },
                                                                        [
                                                                          tag !==
                                                                          "LAB"
                                                                            ? _c(
                                                                                "v-chip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      small:
                                                                                        "",
                                                                                      color:
                                                                                        "grey lighten-1",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.getTipoAtendimento(
                                                                                          tag
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    index <
                                                    _vm.prestadorasFiltradas
                                                      .length -
                                                      1
                                                      ? _c("v-divider", {
                                                          key: `divider-${index}`,
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.tipo === "simulacao"
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "pb-0" },
                                        [
                                          _c(
                                            "h2",
                                            {
                                              staticClass:
                                                "primary--text mb-2 subtitle-1 font-weight-bold",
                                            },
                                            [_vm._v("Reajuste anual")]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              staticClass:
                                                "subtitle-1 text-uppercase",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.meses[
                                                    _vm.plano.mesReajuste
                                                  ]
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.tipo === "simulacao" &&
                              _vm.plano &&
                              _vm.plano.possuiPlanosOpcionais
                                ? _c("detalhes-produtos-opcionais", {
                                    attrs: {
                                      produtosOpcionais: _vm.produtosOpcionais,
                                      titular: _vm.titular,
                                      loading: _vm.loadingProdutosOpcionais,
                                    },
                                  })
                                : _vm._e(),
                              _vm.tipo === "proposta"
                                ? _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "secundaryBtn--text mt-4",
                                              attrs: {
                                                large: "",
                                                type: "submit",
                                                elevation: "10",
                                                block: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.$emit(
                                                    "selecionarplano",
                                                    _vm.plano
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Selecionar plano")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.modalCoparticipacao,
            callback: function ($$v) {
              _vm.modalCoparticipacao = $$v
            },
            expression: "modalCoparticipacao",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _c("div", { staticClass: "modal-title" }, [
                  _vm._v(" Coparticipação "),
                ]),
              ]),
              _c("v-card-text", [
                _c("table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Procedimentos"),
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Valor (R$)"),
                      ]),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v("Valor (%)"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.tabelaCoparticipacao, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { style: { width: "250px" } }, [
                          _vm._v(" " + _vm._s(item.procedimento) + " "),
                        ]),
                        _c(
                          "td",
                          { style: { width: "100px", textAlign: "center" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.tipoValor === "R$" ? item.valor : "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          { style: { width: "100px", textAlign: "center" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.tipoValor === "%" ? item.valor : "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c("v-divider", { staticClass: "mt-6" }),
              _c("v-card-text", { staticClass: "mt-2" }, [
                _vm._v(
                  " As informações apresentadas acima podem ser alteradas pela operadora a qualquer momento. "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }