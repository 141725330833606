import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'fatores-moderadores';

const getTabelaCoparticipacao = async ({idProduto, vigencia}) => {
  try {
    const config = {
      headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_FATORES_MODERADORES_API_KEY }
    };
    
    const { data } = await api.get(`${resource}/${idProduto}?vigencia=${vigencia}`, config);
    return data
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  getTabelaCoparticipacao,
};
