var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.handleClickOutside,
              expression: "handleClickOutside",
            },
          ],
          staticClass: "sidebar",
          attrs: {
            app: "",
            right: "",
            temporary: "",
            "disable-resize-watcher": "",
          },
          model: {
            value: _vm.drawerComp,
            callback: function ($$v) {
              _vm.drawerComp = $$v
            },
            expression: "drawerComp",
          },
        },
        [
          _c(
            "div",
            { staticClass: "sidebar-content" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c("div", { staticClass: "sidebar-title" }, [
                      _vm._v(" Filtros "),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-limpar text-none pa-0",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.limparFiltro.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Limpar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "validation-observer",
                {
                  ref: "form",
                  attrs: { tag: "form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-1 mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("base-wrapper-dialog-options", {
                            key: "a_operadoras-0",
                            ref: "dialogOperadora",
                            attrs: {
                              headerTitle: "Operadora",
                              options: _vm.filtros.dataOperadora
                                ? _vm.filtros.dataOperadora
                                : [],
                              "value-key": "id",
                              "label-key": "nome",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "operadora",
                                  $event.id
                                )
                              },
                              open: function ($event) {
                                _vm.modalOperadora = true
                              },
                              close: function ($event) {
                                _vm.modalOperadora = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            loading: !_vm.filtros.dataOperadora,
                                            readonly: "",
                                            id: "operadora",
                                            placeholder:
                                              "Clique aqui para selecionar",
                                            name: "Operadora",
                                            value: _vm.form.operadora,
                                            label: "Operadora",
                                            outlined: "",
                                            "append-icon": "mdi-chevron-right",
                                            "hide-details": "",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-0 mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("base-wrapper-dialog-options", {
                            key: "a_abrangencia-0",
                            ref: "dialogAbrangencia",
                            attrs: {
                              headerTitle: "Abrangencia",
                              options: _vm.filtros.dataAbrangencia
                                ? _vm.filtros.dataAbrangencia
                                : [],
                              "value-key": "id",
                              "label-key": "nome",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "abrangencia",
                                  $event.id
                                )
                              },
                              open: function ($event) {
                                _vm.modalAbrangencia = true
                              },
                              close: function ($event) {
                                _vm.modalAbrangencia = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            loading:
                                              !_vm.filtros.dataAbrangencia,
                                            readonly: "",
                                            id: "abrangencia",
                                            placeholder:
                                              "Clique aqui para selecionar",
                                            name: "Abrangencia",
                                            value: _vm.form.abrangencia,
                                            label: "Abrangencia",
                                            outlined: "",
                                            "append-icon": "mdi-chevron-right",
                                            "hide-details": "",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-0 mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("base-wrapper-dialog-options", {
                            key: "a_acomodacao-0",
                            ref: "dialogAcomodacao",
                            attrs: {
                              headerTitle: "Acomodação",
                              options: _vm.filtros.dataAcomodacao
                                ? _vm.filtros.dataAcomodacao
                                : [],
                              "value-key": "id",
                              "label-key": "nome",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "acomodacao",
                                  $event.id
                                )
                              },
                              open: function ($event) {
                                _vm.modalAcomodacao = true
                              },
                              close: function ($event) {
                                _vm.modalAcomodacao = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            loading:
                                              !_vm.filtros.dataAcomodacao,
                                            readonly: "",
                                            id: "acomodacao",
                                            placeholder:
                                              "Clique aqui para selecionar",
                                            name: "Acomodação",
                                            value: _vm.form.acomodacao,
                                            label: "Acomodação",
                                            outlined: "",
                                            "append-icon": "mdi-chevron-right",
                                            "hide-details": "",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-0 mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("base-wrapper-dialog-options", {
                            key: "c_coparticipacao-0",
                            ref: "dialogCoparticipaca",
                            attrs: {
                              headerTitle: "Coparticipação",
                              options: _vm.filtro.dataCoparticipacao,
                              "value-key": "id",
                              "label-key": "nome",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "coparticipacao",
                                  $event.nome
                                )
                              },
                              open: function ($event) {
                                _vm.modalCoparticipacao = true
                              },
                              close: function ($event) {
                                _vm.modalCoparticipacao = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            loading: false,
                                            readonly: "",
                                            id: "coparticipacao",
                                            placeholder:
                                              "Clique aqui para selecionar",
                                            name: "Coparticipação",
                                            value: _vm.form.coparticipacao,
                                            label: "Coparticipação",
                                            outlined: "",
                                            "append-icon": "mdi-chevron-right",
                                            "hide-details": "",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mx-5" },
                    [
                      _c("v-col", { staticClass: "pa-0" }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "primary--text subtitle-1 font-weight-bold",
                          },
                          [_vm._v(" Intervalo de Preço ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { sm: "12", md: "2" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              "hide-details": "",
                              "single-line": "",
                              type: "number",
                            },
                            model: {
                              value: _vm.price[0],
                              callback: function ($$v) {
                                _vm.$set(_vm.price, 0, $$v)
                              },
                              expression: "price[0]",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { sm: "12", md: "2" } },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              "hide-details": "",
                              "single-line": "",
                              type: "number",
                            },
                            model: {
                              value: _vm.price[1],
                              callback: function ($$v) {
                                _vm.$set(_vm.price, 1, $$v)
                              },
                              expression: "price[1]",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mx-5" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "flex-grow-1 flex-shrink-0 px-0 py-2",
                          staticStyle: {
                            "min-width": "100px",
                            "max-width": "100%",
                          },
                          attrs: { cols: "1" },
                        },
                        [
                          _c("v-range-slider", {
                            staticClass: "rangePrice",
                            attrs: {
                              max: 24000,
                              min: 0,
                              step: 1,
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.price,
                              callback: function ($$v) {
                                _vm.price = $$v
                              },
                              expression: "price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-0 mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("base-wrapper-dialog-options", {
                            key: "c_ordenar-0",
                            ref: "dialogOrdenar",
                            attrs: {
                              headerTitle: "Ordenar",
                              options: _vm.filtro.dataOrdenar,
                              "value-key": "nome",
                              "label-key": "nome",
                            },
                            on: {
                              select: function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "ordenar",
                                  $event.nome
                                )
                              },
                              open: function ($event) {
                                _vm.modalOrdenacao = true
                              },
                              close: function ($event) {
                                _vm.modalOrdenacao = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            loading: false,
                                            readonly: "",
                                            id: "ordenar",
                                            placeholder: "Por Preço",
                                            name: "Ordenar",
                                            value: _vm.form.ordenar,
                                            label: "Ordenar",
                                            outlined: "",
                                            "append-icon": "mdi-chevron-right",
                                            "hide-details": "",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-2 mx-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0" },
                        [
                          _c("base-wrapper-dialog-list", {
                            key: "d_rede-referenciada-0",
                            attrs: {
                              headerTitle: "Rede Médica",
                              options: _vm.listaPrestadores,
                              "label-key": "prestador",
                              refKey: "idProdutoFatura",
                              "value-key": "id",
                              loading: _vm.loadingRede,
                              selectMultiplos: true,
                              "return-object": "",
                            },
                            on: {
                              select: _vm.selectHospital,
                              open: function ($event) {
                                _vm.modalRedeMedica = true
                              },
                              close: function ($event) {
                                _vm.modalRedeMedica = false
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activador",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "base-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            inputmode: "text",
                                            readonly: "",
                                            id: "redeReferenciada",
                                            name: "Rede Médica",
                                            placeholder: "Selecione",
                                            label: "Rede Médica",
                                            outlined: "",
                                            value: _vm.hospitaisSelecionados,
                                            "append-icon": "mdi-chevron-right",
                                            loading: _vm.loadingRede,
                                            "hide-details": "",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "options",
                                fn: function () {
                                  return [
                                    _c("base-wrapper-dialog-options", {
                                      key: "c_cidades-0",
                                      ref: "dialogCidades",
                                      staticClass: "mt-10",
                                      attrs: {
                                        headerTitle: "Cidades",
                                        options: _vm.redeReferenciada.cidades,
                                        "value-key": "nome",
                                        "label-key": "nome",
                                      },
                                      on: {
                                        select: _vm.selectCidadePrestadores,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activador",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "base-text-field",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      loading: false,
                                                      readonly: "",
                                                      id: "cidade",
                                                      placeholder:
                                                        "Clique aqui para selecionar",
                                                      name: "Cidades",
                                                      value:
                                                        _vm.cidadeSelecionada,
                                                      label: "Cidades",
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-chevron-right",
                                                    },
                                                  },
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mx-5 mt-3" }),
              _c(
                "div",
                { staticClass: "px-5 mt-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", "x-large": "", color: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.enviarFiltro.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" FILTRAR ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }