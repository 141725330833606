<template>
  <div>
    <div>
      <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Produtos Opcionais</h2>
      <v-divider></v-divider>
    </div>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular
        class="mt-4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-expansion-panels v-else-if="produtosOpcionais.length" v-model="panels" focusable inset multiple class="mt-4">
      <v-row no-gutters>
        <v-col
          xs="12" sm="12" md="12" lg="6" xl="6"
          v-for="(produtoOpcional, index) in produtosOpcionais"
          :key="index"
        >
          <v-expansion-panel
            class="mb-3 ma-2"
          >
            <v-expansion-panel-header tile>
              <div class="d-flex align-center">
                <div class="mr-4">
                  <v-icon size="25">mdi-file-document-multiple</v-icon>
                </div>
                <div>
                  <span>{{ produtoOpcional.nomePlanoAns || 'Produto Opcional' }}</span>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-2 mt-2">
                <b>Nome</b><br />
                <span>{{ produtoOpcional.nomePlanoAns || '--' }}</span>
              </div>
              <div class="mb-2">
                <b>Valor Total</b><br />
                <span>{{ produtoOpcional.valorTotal | currency }}</span>
              </div>
              <v-divider class="mb-2" />
              <div class="mb-2 d-flex justify-space-between">
                <div>
                  <b>Operadora</b><br />
                  <span>{{ produtoOpcional.operadora || '--' }}</span>
                </div>
                <div>
                  <b>Abrangência</b><br />
                  <span>{{ produtoOpcional.abrangencia || '--' }}</span>
                </div>
              </div>
              <div class="mb-2 d-flex justify-space-between">
                <div>
                  <b>Segmentação</b><br />
                  <span>{{ produtoOpcional.segmentacao || '--' }}</span>
                </div>
                <div>
                  <b>Reembolso</b><br />
                  <span>{{ produtoOpcional.reembolso ? 'Sim' : 'Não' }}</span>
                </div>
              </div>
              <div class="mb-2">
                <b>Observação</b><br />
                <span>{{ produtoOpcional.observacao || '--' }}</span>
              </div>
              <h2 class="primary--text mb-2 subtitle-1 font-weight-bold">Beneficiários</h2>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" v-for="(beneficiario, index) in produtoOpcional.beneficiarios" :key="index">
                  <base-card-beneficiary
                    :nome="beneficiario.chave || formataData(beneficiario.dataNascimento)"
                    :valor="beneficiario.valorContratual"
                    :valorSemDesconto="beneficiario.valorContratualSemDesconto"
                    :tipo="isTipo(beneficiario)"
                    :avatar="isTipo(beneficiario)"
                  ></base-card-beneficiary>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-col>
      </v-row>
    </v-expansion-panels>
    <div v-else class="d-flex justify-center">
      <span class="mt-4">
        Nenhum registro encontrado
      </span>
    </div>
  </div>
</template>

<script>
import BaseCardBeneficiary from '@/components/BaseCardBeneficiary.vue';
import moment from 'moment';

export default {
  components: {
    BaseCardBeneficiary,
  },
  props: {
    produtosOpcionais: {
      default: [],
    },
    titular: {
      default: {},
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      panels: [],
    };
  },
  watch: {
    produtosOpcionais() {
      this.panels = Array(this.produtosOpcionais.length || 0).fill(0).map((_, i) => i);
    },
  },
  methods: {
    isTipo(beneficiario) {
      return beneficiario.dataNascimento === this.titular.dataNascimento ? 'Titular' : 'Dependente';
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
  },
};
</script>

<style scoped>

</style>
