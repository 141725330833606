<template>
  <v-row>
    <v-col v-for="(plano, index) in model" :key="index" cols="12" md="6" lg="4">
      <div class="default-card">
        <v-row align="center">
          <v-col cols="3" class="text-center">
            <v-list-item-avatar dark size="75" tile class="ml-3 my-0">
              <v-img width="100%" contain :src="plano.operadoraLogo"></v-img>
            </v-list-item-avatar>
          </v-col>
          <v-col>
            <p class="font-weight-bold ma-0 ml-3">
              {{ plano.operadora }}
            </p>
            <p class="font-weight-bold ma-0 ml-3">
              {{ plano.nomePlanoAns }}
            </p>
          </v-col>
        </v-row>
        <span class="span-ans ml-1">
          {{ "ANS nº " + plano.codigoans }}
        </span>
        <v-divider class="mt-3 mb-4" />
        <span>{{ plano.entidade }}</span>
        <v-divider class="mt-3 mb-4" />
        <span>{{ plano.acomodacao }}</span>
        <v-divider class="mt-3 mb-4" />
        <span>{{ plano.segmentacao }}</span>
        <v-divider class="mt-3 mb-4" />
        <span>
          {{ plano.coparticipacao ? "Com coparticipação" : "Sem coparticipação" }}
        </span>
        <v-divider class="mt-3 mb-4" />
        <span>{{ plano.abrangencia }}</span>
        <v-divider class="mt-3 mb-4" />
        <div>Hospitais: {{ retornaPrestadores(plano.idProdutoFatura, "Hospital") }} </div>
        <div>Laboratórios: {{ retornaPrestadores(plano.idProdutoFatura, "Laboratorio") }}</div>
        <v-divider class="mt-3 mb-4" />
        <div v-if="plano.captacao" class="font-weight-bold">Captação</div>
        <div v-if="plano.valorTotalOdontoAdicional" class="div-preco sem-desconto mt-4">
          <span class="span-total">{{ (plano.valorTotal + plano.valorTotalOdontoAdicional) | currency }}</span> / mês
        </div>
        <div v-else-if="plano.valorTotalSemDesconto && plano.valorTotal !== plano.valorTotalSemDesconto" class="div-preco desconto mt-4">
          <p class="ma-0">
            <strike class="strike-desconto">{{ plano.valorTotalSemDesconto | currency }}</strike>
            <span class="span-total">{{ plano.valorTotal | currency }}</span> / mês
          </p>
          <p class="ma-0">
            Consulte as condições da promoção
          </p>
        </div>
        <div v-else class="div-preco sem-desconto mt-4">
          <span class="span-total">{{ plano.valorTotal | currency }}</span> / mês
        </div>
        <v-btn color="primary" outlined block @click="$emit('detalhes', plano)" :data-test-id="`detalhes-${index}`" class="my-5">
          <v-icon left>mdi-eye-outline</v-icon>
          <span class="mt-1 subtitle-2">Mais detalhes</span>
        </v-btn>
        <div class="custom-checkbox">
          <label :for="`check-${plano.idProdutoFatura}`" class="subtitle-2">
            <input
              type="checkbox"
              :name="`check-${plano.idProdutoFatura}`"
              :id="`check-${plano.idProdutoFatura}`"
              :ref="`check-${plano.idProdutoFatura}`"
              @change="changeCheckPlano(plano)"
              :data-test-id="`plano-${index}`"
            />
            <span class="ml-2">
              Selecionar
            </span>
          </label>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import redeService from '@/services/redeReferenciadaService';
export default {
  components: {},
  data() {
    return {
      snackbar: false,
      timeout: 3000,
      color: "sucess",
      resolve: null,
      reject: null,
      message: "Já existe um plano selecionado com entidades diferentes da deste plano.",
      options: {
        top: false,
        bottom: true,
        left: false,
        right: false,
        icon: {
          name: "mdi-close-circle",
          color: "error",
        },
      },
      vteste: [],
      loading: false,
      selected: false,
      planosSelecionados: {},
      planosAgrupados: [],
      prestadores: []
    };
  },
  props: {
    model: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    planos(value) {
      this.selected = value;
    },
  },
  model: {
    prop: "model",
    event: "change",
  },
  methods: {
    retornaPrestadores(idProdutoFatura, tipoPrestador) {
      return this.prestadores.filter(p => p.idProdutoFatura.includes(idProdutoFatura) && p.tipoPrestador === tipoPrestador).length
    },
    isSelected() {
      return this.selected;
    },
    changeCheckPlano(item) {
      const plano = {
        ...item,
        laboratorios: this.retornaPrestadores(item.idProdutoFatura, "Hospital"),
        hospitais: this.retornaPrestadores(item.idProdutoFatura, "Laboratorio")
      }
      const checkEl = this.$refs[`check-${item.idProdutoFatura}`][0];
      const permite = this.handleEntidades(plano, !!checkEl.checked);

      if (permite) checkEl.checked = !!checkEl.checked;
      else checkEl.checked = false;

      const entidadesAgrupadas = this.agrupaEntidades();
      this.$emit("select-plano", { planosAgrupados: this.planosAgrupados, entidadesAgrupadas });
    },
    handleEntidades(plano, checked) {
      if (checked) {
        this.planosAgrupados.push(plano);
        return true;
      }
      let entidades = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const p of this.planosAgrupados) {
        entidades = [...entidades, ...p.entidades];
      }

      const { planosAgrupados } = this;
      let res;

      if (checked) {
        for (let index = 0; index < plano.entidades.length; index++) {
          if (planosAgrupados.filter((p) => p.entidades.includes(plano.entidades[index])).length) {
            this.planosAgrupados.push(plano);
            res = true;
            break;
          }
        }

        if (!res) this.snackbar = true;
      } else {
        this.planosAgrupados = this.planosAgrupados.filter((p) => p.idProdutoFatura !== plano.idProdutoFatura);
        res = false;
      }

      return res;
    },
    agrupaEntidades() {
      let entidades = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const p of this.planosAgrupados) {
        entidades = [...entidades, ...p.entidades];
      }

      entidades = [...new Set(entidades)];
      return entidades;
    },
    async redeReferenciada() {
      try {
        const { data } = await redeService.getRedeReferenciada({
          idProdutoFatura: this.model.map(m => m.idProdutoFatura), UF: this.model[0].uf,
        });
        this.prestadores = data.prestadores
      } catch (error) {
        this.cidades = [];
        this.prestadores = [];
      }
    },
  },
  async mounted() {
    await this.redeReferenciada()
  },
  computed: {
    modelComputed() {
      return this.model;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.default-card {
  height: auto;
  width: 20rem;
  padding: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b3b1b0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin: auto;
  @media (max-width: $media-sm) {
    width: 22rem;
  }
}

.span-ans {
  background: #000000;
  color: #ffffff;
  font-size: 0.6em;
  padding: 0.2rem 0.5rem 0 0.5rem;
  display: inline-block;
}

.div-preco {
  margin: 0 -15px 0 -15px;
  padding: 1.5rem 1rem 1.5rem 1rem;
  background: #f4f2f1;
  &.desconto {
    background: #ffb61e;
    .strike-desconto {
      font-size: 1.5em;
    }
    .span-total {
      font-size: 1.8em;
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }
  &.sem-desconto {
    .span-total {
      font-size: 1.8em;
      font-weight: bold;
    }
  }
}

.custom-checkbox {
  display: flex;
  label {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    span {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
  input {
    height: 20px;
    width: 20px;
  }
}
</style>
