var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.model, function (plano, index) {
      return _c(
        "v-col",
        { key: index, attrs: { cols: "12", md: "6", lg: "4" } },
        [
          _c(
            "div",
            { staticClass: "default-card" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-list-item-avatar",
                        {
                          staticClass: "ml-3 my-0",
                          attrs: { dark: "", size: "75", tile: "" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              width: "100%",
                              contain: "",
                              src: plano.operadoraLogo,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col", [
                    _c("p", { staticClass: "font-weight-bold ma-0 ml-3" }, [
                      _vm._v(" " + _vm._s(plano.operadora) + " "),
                    ]),
                    _c("p", { staticClass: "font-weight-bold ma-0 ml-3" }, [
                      _vm._v(" " + _vm._s(plano.nomePlanoAns) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c("span", { staticClass: "span-ans ml-1" }, [
                _vm._v(" " + _vm._s("ANS nº " + plano.codigoans) + " "),
              ]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              _c("span", [_vm._v(_vm._s(plano.entidade))]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              _c("span", [_vm._v(_vm._s(plano.acomodacao))]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              _c("span", [_vm._v(_vm._s(plano.segmentacao))]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      plano.coparticipacao
                        ? "Com coparticipação"
                        : "Sem coparticipação"
                    ) +
                    " "
                ),
              ]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              _c("span", [_vm._v(_vm._s(plano.abrangencia))]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              _c("div", [
                _vm._v(
                  "Hospitais: " +
                    _vm._s(
                      _vm.retornaPrestadores(plano.idProdutoFatura, "Hospital")
                    ) +
                    " "
                ),
              ]),
              _c("div", [
                _vm._v(
                  "Laboratórios: " +
                    _vm._s(
                      _vm.retornaPrestadores(
                        plano.idProdutoFatura,
                        "Laboratorio"
                      )
                    )
                ),
              ]),
              _c("v-divider", { staticClass: "mt-3 mb-4" }),
              plano.captacao
                ? _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v("Captação"),
                  ])
                : _vm._e(),
              plano.valorTotalOdontoAdicional
                ? _c("div", { staticClass: "div-preco sem-desconto mt-4" }, [
                    _c("span", { staticClass: "span-total" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            plano.valorTotal + plano.valorTotalOdontoAdicional
                          )
                        )
                      ),
                    ]),
                    _vm._v(" / mês "),
                  ])
                : plano.valorTotalSemDesconto &&
                  plano.valorTotal !== plano.valorTotalSemDesconto
                ? _c("div", { staticClass: "div-preco desconto mt-4" }, [
                    _c(
                      "p",
                      { staticClass: "ma-0" },
                      [
                        _c("strike", { staticClass: "strike-desconto" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(plano.valorTotalSemDesconto)
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "span-total" }, [
                          _vm._v(_vm._s(_vm._f("currency")(plano.valorTotal))),
                        ]),
                        _vm._v(" / mês "),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "ma-0" }, [
                      _vm._v(" Consulte as condições da promoção "),
                    ]),
                  ])
                : _c("div", { staticClass: "div-preco sem-desconto mt-4" }, [
                    _c("span", { staticClass: "span-total" }, [
                      _vm._v(_vm._s(_vm._f("currency")(plano.valorTotal))),
                    ]),
                    _vm._v(" / mês "),
                  ]),
              _c(
                "v-btn",
                {
                  staticClass: "my-5",
                  attrs: {
                    color: "primary",
                    outlined: "",
                    block: "",
                    "data-test-id": `detalhes-${index}`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("detalhes", plano)
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-eye-outline"),
                  ]),
                  _c("span", { staticClass: "mt-1 subtitle-2" }, [
                    _vm._v("Mais detalhes"),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "custom-checkbox" }, [
                _c(
                  "label",
                  {
                    staticClass: "subtitle-2",
                    attrs: { for: `check-${plano.idProdutoFatura}` },
                  },
                  [
                    _c("input", {
                      ref: `check-${plano.idProdutoFatura}`,
                      refInFor: true,
                      attrs: {
                        type: "checkbox",
                        name: `check-${plano.idProdutoFatura}`,
                        id: `check-${plano.idProdutoFatura}`,
                        "data-test-id": `plano-${index}`,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeCheckPlano(plano)
                        },
                      },
                    }),
                    _c("span", { staticClass: "ml-2" }, [
                      _vm._v(" Selecionar "),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }