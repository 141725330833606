<template>
    <v-dialog
        no-click-animation
        v-model="value"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
    <v-card tile>
        <the-nav-bar title="Rede referenciada" @back="close"></the-nav-bar>
        <v-container>
            <v-row>
                <v-col xs="2" sm="2" md="2" lg="2" xl="2">
                    <base-autocomplete-field
                        :disabled="itemSelectUf.length < 1"
                        :items="itemSelectUf"
                        id="id"
                        name="UF"
                        label="UF"
                        v-model="form.uf"
                        mode="aggressive"
                        outlined
                        return-object
                    >
                    </base-autocomplete-field>
                </v-col>
                <v-col xs="6" sm="6" md="6" lg="6" xl="6">
                    <base-autocomplete-field
                        :disabled="cidades.length < 1"
                        :items="cidades"
                        id="id"
                        name="Cidade"
                        label="Cidade"
                        v-model="form.cidade"
                        mode="aggressive"
                        outlined
                        return-object
                        clearable
                    >
                    </base-autocomplete-field>
                </v-col>
                <v-col xs="4" sm="4" md="4" lg="4" xl="4">
                    <base-autocomplete-field
                        :items="especialidade"
                        name="Especialidade"
                        label="Especialidade"
                        item-text="name"
                        item-value="id"
                        v-model="form.especialidade"
                        mode="aggressive"
                        id="id"
                        outlined
                        return-object
                        clearable
                    >
                    </base-autocomplete-field>
                </v-col>
            </v-row>
            <div
                v-for="(pres, index) in prestadoresFiltrados"
                class="sobre-itens my-6"
                :key="index"
            >
                <v-card
                :class="index % 2 == 0 ? 'primary' : 'white'"
                :dark="index % 2 == 0"
                class="elevation-3"
                >
                    <v-card-title class="pt-7" >{{ pres.prestador }} </v-card-title>
                    <v-card-text class="quebra-texto"> Endereço: {{ pres.cidade }} - {{ pres.estado }}</v-card-text>
                    <v-card-text class="quebra-texto"> Especialidade: {{ retornaEspecialidade(pres.tipoAtendimento) }} </v-card-text>
                </v-card>
            </div>
        </v-container>
    </v-card>
    </v-dialog>
</template>

<script>
import BaseAutocompleteField from '@/components/BaseAutocompleteField.vue';
import TheNavBar from '@/components/TheNavBar.vue';
import ufList from "@/utils/constants/ufList";
import redeService from '@/services/redeReferenciadaService';
export default {
    name: 'RedeReferenciada',
    components: {
        BaseAutocompleteField,
        TheNavBar
    },
    props: {
        value: {
            default: false,
        },
        idProdutoFatura: {
            default: ""
        },
        uf: {
            default: ""
        }
    },
    data() {
        return {
            itemSelectUf: ufList.map((item) => item.nome),
            form: {
                uf: "",
                cidade: "",
                especialidade: ""
            },
            especialidade: [ { id:"PS", name: "Pronto Socorro" }, { id: "INT", name: "Internação" }, { id: "MAT", name:"Maternidade" }, { id: "LAB", name: "Laboratório" } ],
            cidades: [],
            prestadores: []
        }
    },
    computed: {
        prestadoresFiltrados() {
            return  this.form.cidade || this.form.cidade.id ? this.prestadores.filter( pr => pr.cidade === this.form.cidade && pr.tipoAtendimento.includes(this.form.especialidade.id) ) : this.prestadores
        }
    },
    methods: {
        retornaEspecialidade(tipoAtendimento) {
            return this.especialidade.filter( e => tipoAtendimento.includes( e.id )).map( m => m.name ).toString()
        },
        close() {
            this.form = {
                uf: this.uf,
                cidade: "",
                especialidade: ""
            }
            this.$emit('input', false);
        },
        async redeMedica(form) {
            try {
                const data = await redeService.getRedeReferenciada({
                    idProdutoFatura: [this.idProdutoFatura], UF: form.uf,
                });
                const { cidades, prestadores } = data.data;
                this.cidades = cidades.sort((a, b) => a.localeCompare(b));
                this.prestadores = prestadores
            } catch (error) {
                this.cidades = [];
            }
        }
    },
    mounted() {
        this.form.uf = this.uf
    },
    watch: {
        form: {
            async handler(newVal) {
                await this.redeMedica({ uf: newVal.uf })
            },
            deep: true
        }
    }
}
</script>